import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Button, DropdownButton, MenuItem } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { createStructuredSelector } from 'reselect';

import EditContainer from '../components/EditContainer';
import { handleGravitonError } from '../utils';
import { getLanguage } from '../selectors/commonSelectors';
import {
  getVisiblePromotions,
  getTotalCount,
  getSections,
  getSchema,
  getModules,
  getCustomerSegments,
  getLoading,
  getChanged,
  getError,
  getFilter,
} from '../selectors/Promotions';
import {
  LOAD_PROMOTIONS_ACTIONS,
  FILTER_PROMOTIONS_ACTIONS,
  SHOW_MORE_PROMOTIONS_ACTIONS,
  SAVE_PROMOTIONS_ACTIONS,
  REMOVE_PROMOTIONS_ACTIONS,
} from '../actions/PromotionActions';
import { PromotionsItem } from '../components/Promotions/PromotionsItem';

class Promotions extends React.Component {
  constructor(props) {
    super(props);
    props.load();

    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
  }

  save(promotion, sections) {
    const { savePromotion } = this.props;
    savePromotion(promotion, sections);
  }

  remove(promotion) {
    const { removePromotion } = this.props;
    removePromotion(promotion);
  }

  renderTitle() {
    const { setFilterHandler, filter: { show } } = this.props;
    return (
      <span>
        <span style={{marginRight: '10px'}}>Promotionen</span>
        <DropdownButton onSelect={setFilterHandler} title="Filter" bsStyle="primary" id="filter">
          <MenuItem eventKey="all" active={show === 'all'}>Alle Promotionen</MenuItem>
          <MenuItem eventKey="active" active={show === 'active'}>Aktive Promotionen</MenuItem>
          <MenuItem eventKey="inactive" active={show === 'inactive'}>Inaktive Promotionen</MenuItem>
        </DropdownButton>
      </span>
    );
  }

  renderOverview() {
    const {
      promotions,
      schema,
      sections,
      language,
      customerSegments,
      totalCount,
      modules,
      loading,
      error,
      showMoreHandler,
    } = this.props;

    if (loading || error) {
      return null;
    }

    const promotionItems = [
      {isNew: true, promotion: {id: v4()}},
      ...promotions.map((promotion) => ({promotion})),
    ].map((item) => (
      <PromotionsItem
        key={item.promotion.id}
        isNew={item.isNew}
        onSave={this.save}
        promotion={item.promotion}
        customerSegments={customerSegments}
        sections={sections}
        language={language}
        schema={schema}
        modules={modules}
        removePromotion={this.remove}
      />
    ));

    const moreButton = promotions.length < totalCount
      ? <Button bsSize="large" bsStyle="primary" onClick={showMoreHandler}>Mehr anzeigen</Button>
      : null;

    return (
      <div>
        <Row style={{ minHeight: '220px' }}>
          { promotionItems }
        </Row>
        <div className="text-center">{moreButton}</div>
      </div>
    );
  }

  render() {
    const { loading, error } = this.props;
    if (error) {
      toast.error(handleGravitonError(error));
    }

    return (
      <EditContainer
        requesting={loading}
        title={this.renderTitle()}
        body={this.renderOverview()}
      />
    );
  }
}

Promotions.propTypes = {
  promotions: PropTypes.array,
  modules: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.object,
  filter: PropTypes.object,
  schema: PropTypes.object,
  sections: PropTypes.array,
  language: PropTypes.string,
  customerSegments: PropTypes.array,
  totalCount: PropTypes.number,
};

Promotions.defaultProps = {
  promotions: [],
  modules: [],
  loading: true,
  error: undefined,
  filter: {},
  schema: {},
  sections: [],
  language: 'en',
  customerSegments: [],
  totalCount: 0,
};

const mapStateToProps = createStructuredSelector({
  language: getLanguage,
  promotions: getVisiblePromotions,
  totalCount: getTotalCount,
  sections: getSections,
  schema: getSchema,
  modules: getModules,
  customerSegments: getCustomerSegments,
  loading: getLoading,
  changed: getChanged,
  filter: getFilter,
  error: getError,
});

const mapDispatchToProps = {
  load: () => LOAD_PROMOTIONS_ACTIONS.request(),
  setFilterHandler: (mode) => FILTER_PROMOTIONS_ACTIONS.request({mode}),
  showMoreHandler: () => SHOW_MORE_PROMOTIONS_ACTIONS.request(),
  savePromotion: (promotion, sections) => SAVE_PROMOTIONS_ACTIONS.request({promotion, sections}),
  removePromotion: (promotion) => REMOVE_PROMOTIONS_ACTIONS.request({promotion}),
};

export default connect(mapStateToProps, mapDispatchToProps)(Promotions);
