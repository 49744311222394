import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, Col } from 'react-bootstrap';
import Select from 'react-select';
import _, { get } from 'lodash';
import Toggle from 'react-toggle';
import { FormattedMessage } from 'react-intl';

import HiddenCols from './HiddenCols';

class AuthorizationsConfiguration extends React.Component {
  constructor(props) {
    super(props);

    const { id, editValueField, item } = props;

    this.types = [
      {value: "authorizations", label: "Partner hat folgende Vollmachten"},
      {value: "authorized", label: "Bevollmächtigt auf diesem Partner sind"}
    ];

    // Set the default type
    if (!item.props.type) editValueField(id, 'componentType', 'authorizations');
  }

  render() {
    const { id, item, editValueField } = this.props;

    return (
      <React.Fragment>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Welche Vollmachten:
          </Col>
          <Col sm={9}>
            <Select
              placeholder="Eingabefeld Ausprägung wählen"
              clearable={false}
              name={`components.${id}.props.type`}
              options={this.types}
              value={item.props.type}
              onChange={(selectedOption) => editValueField(id, 'componentType', get(selectedOption, 'value'))}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="codeGroupMulti">
          <Col componentClass={ControlLabel} sm={3}>
            <FormattedMessage id="Workguide.Configuration.Common.MultiSelect" />
          </Col>
          <Col sm={9} style={{ paddingTop: '10px' }}>
          <Toggle
              checked={_.get(item, 'props.multi')===undefined?false:item.props.multi}
              onChange={() => editValueField(id, 'multi')}
          />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Spaten anzeigen
          </Col>
          <Col sm={9} style={{ paddingTop: '10px' }}>
            <HiddenCols
              component={item}
              options={[{
                value: 'contractType',
                label: 'Vertragstyp'
              }, {
                value: 'accountNumber',
                label: 'Kontonummer'
              }, {
                value: 'designation',
                label: 'Kundenbezeichnung'
              }, {
                value: 'signatureRight',
                label: 'Zeichnungsrecht'
              }]}
              onChange={(value) => editValueField(id, 'hiddenCols', value)}
            />
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
}

AuthorizationsConfiguration.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  item: PropTypes.object.isRequired,
  editValueField: PropTypes.func,
  language: PropTypes.string
};

AuthorizationsConfiguration.defaultProps = {
  editValueField: _.noop,
  language: 'de'
};

export default AuthorizationsConfiguration;
