import React from 'react';
import PropTypes from 'prop-types';
import { has, get, map, filter, find, isUndefined, compact, noop } from 'lodash';
import { ListGroup, ListGroupItem, DropdownButton, MenuItem } from 'react-bootstrap';
import { Toggle, CodeSelect } from '@evoja-web/react-form';
import { FormattedMessage } from 'react-intl';
import update from 'immutability-helper';

import * as available from './Validations/index';

function getPossibleValidations(component) {
  const type = get(component, 'component');

  return filter(available, (a) => a.availableFor.includes(type));
}

class FieldValidations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validations: props.validations
    };

    const { codes, fetchCodes } = props;
    if (!has(codes, 'workguideStatus')) {
      fetchCodes('workguideStatus');
    }

    this.onAdd = this.onAdd.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onParamsChange = this.onParamsChange.bind(this);
    this.onConfigChange = this.onConfigChange.bind(this);
  }

  /**
   * Handle params change
   *
   * @param   {Object}  validation  Validation object {@see available validations}
   * @param   {Mixed}   params      Validation params
   *
   * @return  void
   */
  onParamsChange(validation, params) {
    const { validations, onChange } = this.props;

    const key = get(validation, 'key');
    const current = {
      config: get(validations, `${key}.config`, {}),
      validations: get(validations, `${key}.validations`, {})
    };

    const updated = update(current, {
      validations: { $set: validation.get(params) }
    });

    const u = update(validations, {
      [key]: { $set: updated }
    });

    onChange(u);
  }

  /**
   * Handle config value change
   *
   * @param   {Object}  validation  Validation object {@see available validations}
   * @param   {String}  id          Form element id
   * @param   {Mixed}   value       Form value
   *
   * @return  void
   */
  onConfigChange(validation, id, value) {
    const { validations, onChange } = this.props;

    const key = get(validation, 'key');
    const current = {
      config: get(validations, `${key}.config`, {}),
      validations: get(validations, `${key}.validations`, {})
    };

    const updated = update(current, {
      config: {
        [id]: { $set: value }
      }
    });

    const u = update(validations, {
      [key]: { $set: updated }
    });

    onChange(u);
  }

  /**
   * Remove the given validation
   *
   * @param   {String} key Validation key
   *
   * @return  void
   */
  onRemove(key) {
    const { validations, onChange } = this.props;

    const updated = update(validations, { $unset: [key] });

    onChange(updated);
  }

  /**
   * Add a new validation
   *
   * @param   {Object}  validation  Validation object {@see available validations}
   * @param   {Mixed}   params      Validation params
   *
   * @return  void
   */
  onAdd(validation, params = []) {
    const { validations, onChange } = this.props;

    const key = get(validation, 'key');
    const updated = update(validations, {
      [key]: {
        $set: { config: {}, validations: validation.get(params) }
      }
    });

    onChange(updated);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      codes,
      component,
      validations
    } = this.props;

    const options = getPossibleValidations(component)
      .filter((o) => isUndefined(validations[o.key]))
      .map((o) => (
        <MenuItem key={o.validation} onClick={() => this.onAdd(o)}>
          {o.label}
        </MenuItem>
      ));

    const items = compact(map(validations, (v, key) => {
      const validation = find(available, (a) => a.key === key);

      // In some cases (e.g. file meta) the validation can't be edited by the user.
      if (isUndefined(validation)) return undefined;

      // Get possible configuration form for the given validation
      const ConfigurationForm = get(validation, 'ConfigurationForm');

      // Get current definition from component props
      const definition = get(component, `props.validations.${validation.key}`);
      // Check if the current value is a legacy value and take the form value from correct path
      const params = has(definition, 'validations')
        ? get(definition, `validations.${validation.key}`)
        : get(definition, `${validation.key}`);

      const ignoreOnWorkguideStatusValue = get(codes, 'workguideStatus', [])
        .filter((c) => get(definition, 'config.ignoreOnWorkguideStatus', []).includes(c.id));

      return (
        <ListGroupItem key={key}>
          <div className="d-flex align-items-center justify-content-between">
            <strong>
              {validation.label}
            </strong>

            <div
              className="glyphicon glyphicon-remove"
              onClick={() => this.onRemove(key)}
              style={{ color: 'grey' }}
            />
          </div>

          <div>
            {ConfigurationForm && (
              <ConfigurationForm
                component={component}
                validation={validation}
                params={params}
                onChange={(params) => this.onParamsChange(validation, params)}
              />
            )}
          </div>

          <div style={{ paddingBottom: '0.5em' }}>
            <FormattedMessage id="Workguide.Configuration.Common.Validation.IgnoreOnWorkguideStatus" />

            <CodeSelect
              id="ignoreOnWorkguideStatus"
              codes={get(codes, 'workguideStatus')}
              onChange={(...args) => this.onConfigChange(validation, ...args)}
              multi
              returnValue="code.id"
              value={ignoreOnWorkguideStatusValue}
            />
          </div>
        </ListGroupItem>
      );
    }));

    return (
      <div>
        <div style={{ textAlign: 'left' }}>
          <DropdownButton
            id="field-validations"
            disabled={options.length < 1}
            title={<i className="glyphicon glyphicon-plus" />}
            style={{ backgroundColor: '#fff', borderColor: '#fff' }}
          >
            {options}
          </DropdownButton>
        </div>

        <ListGroup>
          {items}
        </ListGroup>
      </div>
    );
  }
}

FieldValidations.propTypes = {
  codes: PropTypes.object,
  component: PropTypes.object.isRequired,
  fetchCodes: PropTypes.func,
  onChange: PropTypes.func,
  validations: PropTypes.object
};

FieldValidations.defaultProps = {
  codes: {},
  fetchCodes: noop,
  onChange: noop,
  validations: {}
};

export default FieldValidations;
