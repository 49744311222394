import React from 'react';
import update from 'immutability-helper';
import { v4 } from 'uuid';
import { Button } from 'react-bootstrap';
import { SortableContainer, arrayMove } from 'react-sortable-hoc';
import ChecklistItem from './ChecklistItem';


const Sortable = SortableContainer(({ items, onItemChange, onItemAdd, onItemRemove, language }) => {
  const list = items.map((item, key) => (
    <ChecklistItem
      key={item.id}
      id={key}
      index={key}
      item={item}
      language={language}
      onItemChange={onItemChange}
      onItemRemove={onItemRemove}
    />
  ));

  return (
    <div>
      <div className="SortableHOCList">
        { list }
      </div>
      <Button
        bsStyle="primary"
        name="newItem"
        onClick={onItemAdd}>
        <span className="icon-061-plus" /> Neuer Eintrag
      </Button>
    </div>
  );
});

export default class Checklist extends React.Component {
  constructor(props) {
    super(props);

    this.onChecklistItemAdd = this.onChecklistItemAdd.bind(this);
    this.onChecklistItemChange = this.onChecklistItemChange.bind(this);
    this.onChecklistItemRemove = this.onChecklistItemRemove.bind(this);
  }
  onChecklistItemAdd() {
    const { section, onSectionChange, language } = this.props;
    const item = {
      id: v4(),
      defaultCheckedState: false,
      order: section.items.length,
      isChecked: false,
      isOwnItem: false,
      name: { [language]: '' },
    };
    onSectionChange(update(section, {
      items: { $push: [item] },
    }));
  }

  onChecklistItemChange(index, event) {
    const { section, language, onSectionChange } = this.props;
    onSectionChange(update(section, {
      items: {[index]: {name: {[language]: {$set: event.target.value}}}},
    }));
  }

  onChecklistItemRemove(index) {
    const { section, onSectionChange } = this.props;
    onSectionChange(update(section, {
      items: {$splice: [[index, 1]]},
    }));
  }

  onChecklistSort(oldIndex, newIndex) {
    const { section, onSectionChange } = this.props;
    const ordered = arrayMove([...section.items], oldIndex, newIndex);

    onSectionChange(update(section, {
      items: {$set: ordered.map((item, key) => update(item, {order: {$set: key}}))},
    }));
  }

  render() {
    const { section, language } = this.props;

    return (
      <Sortable
        items={section.items}
        onItemAdd={this.onChecklistItemAdd}
        onItemChange={this.onChecklistItemChange}
        onItemRemove={this.onChecklistItemRemove}
        onSortEnd={({oldIndex, newIndex}) => this.onChecklistSort(oldIndex, newIndex)}
        language={language}
        axis={'xy'}
        distance={10}
      />
    );
  }
}
