import IsGeInput from '../configuration/isGeInput';

export default {
  key: 'isGe',
  label: 'Minimalwert' ,
  validation: 'isGe',
  availableFor: ['Input'],
  ConfigurationForm: IsGeInput,
  get: function(params) {
    return { isGe: params };
  }
}
