import React from 'react';
import PropTypes from 'prop-types';
import { get, isInteger, noop } from 'lodash';
import { FormGroup } from 'react-bootstrap';
import { NumberInput } from '@evoja-web/react-form';

class MaxStringLengthConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.onValueChange = this.onValueChange.bind(this);
    this.isInputAllowed = this.isInputAllowed.bind(this);
  }

  onValueChange(key, value) {
    const { onChange } = this.props;

    onChange([value]);
  }

  isInputAllowed(values) {
    const floatValue = get(values, 'floatValue', 0);

    return isInteger(floatValue);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { params } = this.props;

    return (
      <FormGroup style={{ paddingLeft: '15px', paddingRight: '15px' }}>
        <NumberInput
          className="form-control"
          onChange={this.onValueChange}
          value={get(params, 0)}
          isAllowed={this.isInputAllowed}
        />
      </FormGroup>
    );
  }
}

MaxStringLengthConfiguration.propTypes = {
  onChange: PropTypes.func,
  params: PropTypes.array
};

MaxStringLengthConfiguration.defaultProps = {
  onChange: noop,
  params: []
};

export default MaxStringLengthConfiguration;
