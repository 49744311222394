import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import NumberFormat from 'react-number-format';
import { FormGroup, ControlLabel } from 'react-bootstrap';

class IsDivisibleBy extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange({ floatValue }) {
    const { onChange } = this.props;

    onChange([floatValue]);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { params } = this.props;

    return (
      <div style={{ paddingRight: '15px', paddingLeft: '15px' }}>
        <FormGroup>
          <ControlLabel>
            Minimum Wert
          </ControlLabel>

          <NumberFormat
            id="min-value"
            className="form-control"
            value={get(params, 0)}
            onValueChange={this.onChange}
            thousandSeparator="'"
            decimalSeparator="."
            decimalScale={2}
          />
        </FormGroup>
      </div>
    );
  }
}

IsDivisibleBy.propTypes = {
  onChange: PropTypes.func,
  params: PropTypes.array
};

IsDivisibleBy.defaultProps = {
  onChange: noop,
  params: []
};

export default IsDivisibleBy;
