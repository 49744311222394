import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import Permissions from '../../../../General/Configuration/Permissions';

// Avaliable roles
const roles = [{
  id: 'AllUsers',
  label: 'Alle Benutzer'
}, {
  id: 'Administrator',
  label: 'Administrator'
}, {
  id: 'Backoffice',
  label: 'Backoffice'
}, {
  id: 'Kdc',
  label: 'Kdc'
}, {
  id: 'LeadDistributor',
  label: 'Lead Distributor'
}, {
  id: 'Postfinance',
  label: 'Postfinance'
}, {
  id: 'UvConsultant',
  label: 'UV Berater'
}, {
  id: 'PledgeResolve',
  label: 'Pledge Resolve'
}];

// Availabel permissions
const permissions = [{
  id: 'Create',
  scope: 'Workflow',
  label: 'erstellen'
}, {
  id: 'Edit',
  scope: 'Workflow',
  label: 'Formulardaten bearbeiten'
}, {
  id: 'Approve',
  scope: 'Workflow',
  label: 'freigeben'
}, {
  id: 'Reject',
  scope: 'Workflow',
  label: 'zurückweisen'
}];

const FormFieldPermissions = React.memo(function FormFieldPermissions({
  id,
  onChange,
  value
}) {
  return (
    <Permissions
      id={id}
      onChange={onChange}
      permissions={permissions}
      roles={roles}
      value={value}
    />
  );
});

FormFieldPermissions.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.array
};

FormFieldPermissions.defaultProps = {
  id: 'permissions',
  onChange: noop,
  value: []
};

export default FormFieldPermissions;
