import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isNil } from 'lodash';
import { FormGroup, ControlLabel, Col } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { FormattedMessage } from 'react-intl';

import CustomerOverwrite from './SpecialComponentConfig/CustomerOverwrite';
import getSpecialConfigValue from '../Utils/Component/getSpecialConfigValue';

class AccountSelectConfiguration extends React.PureComponent {
  render() {
    const {
      id,
      item,
      items,
      editValueField,
      language,
      onSpecialConfigurationChange
    } = this.props;
    const onlyActiveItems = get(item, 'props.onlyActiveItems', false);
    const onlyPaymentAccounts = getSpecialConfigValue(item, 'onlyPaymentAccounts', false);
    const includeAuthorizedAccounts = getSpecialConfigValue(item, 'includeAuthorizedAccounts', false);

    return (
      <React.Fragment>
        <FormGroup controlId="codeGroupMulti">
          <Col componentClass={ControlLabel} sm={3}>
            <FormattedMessage id="Workguide.Configuration.Common.MultiSelect" />
          </Col>
          <Col sm={9} style={{ paddingTop: '10px' }}>
            <Toggle
              checked={isNil(get(item, 'props.multi')) ? false : item.props.multi}
              onChange={() => editValueField(id, 'multi')}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="onlyActiveItems">
          <Col componentClass={ControlLabel} sm={3}>
            Nur aktive Konten
          </Col>
          <Col sm={9} style={{ paddingTop: '10px' }}>
            <Toggle
              checked={onlyActiveItems}
              onChange={() => editValueField(id, 'prop', { key: 'onlyActiveItems', value: !onlyActiveItems })}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="onlyActiveItems">
          <Col componentClass={ControlLabel} sm={3}>
            Nur Zahlungskonten
          </Col>
          <Col sm={9} style={{ paddingTop: '10px' }}>
            <Toggle
              checked={onlyPaymentAccounts}
              onChange={() => onSpecialConfigurationChange('onlyPaymentAccounts', !onlyPaymentAccounts)}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="includeAuthorizedAccounts">
          <Col componentClass={ControlLabel} sm={3}>
            Inkl. Berechtigte Konten des Kunden
          </Col>
          <Col sm={9} style={{ paddingTop: '10px' }}>
            <Toggle
              checked={includeAuthorizedAccounts}
              onChange={() => onSpecialConfigurationChange('includeAuthorizedAccounts', !includeAuthorizedAccounts)}
            />
          </Col>
        </FormGroup>

        <CustomerOverwrite
          item={item}
          items={items}
          language={language}
          onSpecialConfigurationChange={onSpecialConfigurationChange}
        />
      </React.Fragment>
    );
  }
}

AccountSelectConfiguration.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  item: PropTypes.object.isRequired,
  items: PropTypes.array,
  editValueField: PropTypes.func,
  language: PropTypes.string,
  onSpecialConfigurationChange: PropTypes.func
};

AccountSelectConfiguration.defaultProps = {
  editValueField: noop,
  items: [],
  language: 'de',
  onSpecialConfigurationChange: noop
};

export default AccountSelectConfiguration;
