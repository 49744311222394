import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { ControlLabel, FormGroup } from 'react-bootstrap';

import { PermissionConfiguration } from '../../../packages/Workguide/index';

// Avaliable roles
const roles = [{
  id: 'AllUsers',
  label: 'Alle Benutzer'
}, {
  id: 'Administrator',
  label: 'Administrator'
}, {
  id: 'Backoffice',
  label: 'Backoffice'
}, {
  id: 'Kdc',
  label: 'Kdc'
}, {
  id: 'LeadDistributor',
  label: 'Lead Distributor'
}, {
  id: 'Postfinance',
  label: 'Postfinance'
}, {
  id: 'UvConsultant',
  label: 'UV Berater'
}, {
  id: 'PledgeResolve',
  label: 'Pledge Resolve'
}];

// Availabel permissions
const permissions = [{
  id: 'Create',
  scope: 'Workguide',
  label: 'erstellen'
}, {
  id: 'Edit',
  scope: 'Workguide',
  label: 'bearbeiten'
}];

class WorkguidePermissions extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  /**
   * Handle value change
   *
   * @param   {String}  id        Form element id
   * @param   {Array}   selected  Selected values
   *
   * @return  void
   */
  onChange(id, selected) {
    const { editValueField } = this.props;

    editValueField('', 'workguidePropery', { key: 'permissions', value: selected });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { workguide } = this.props;

    return (
      <FormGroup>
        <ControlLabel>
          Workguide Berechtigungen
        </ControlLabel>

        <PermissionConfiguration
          id="permissions"
          onChange={this.onChange}
          permissions={permissions}
          roles={roles}
          value={get(workguide, 'permissions')}
        />
      </FormGroup>
    );
  }
}

WorkguidePermissions.propTypes = {
  editValueField: PropTypes.func,
  workguide: PropTypes.object.isRequired
};

WorkguidePermissions.defaultProps = {
  editValueField: noop
};

export default WorkguidePermissions;
