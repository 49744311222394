import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isNil, first } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { ButtonSelect } from '@evoja-web/react-form';
import update from 'immutability-helper';
import cl from 'classnames';

import Collapsible from '../../../../General/Collapsible';
import Label from '../Field/Label';
import Text from '../Field/Text';
import Description from '../Field/Description';
import ConsultantSelect from '../Field/ConsultantSelect';
import CustomJmesInput from '../Field/CustomJmesInput';
import Toggle from '../Field/Toggle';
import Comment from '../Field/Comment';
import DateField from '../Field/Date';
import WorkguidePermissions from '../Field/Permissons';

const assigneeTypeOptions = [{
  value: 'jmes:session.id',
  label: <FormattedMessage id="Workguide.Workflow.Form.EditNode.AssigneeType.CurrentUser" />
}, {
  value: 'jmes:customer.consultant.id',
  label: <FormattedMessage id="Workguide.Workflow.Form.EditNode.AssigneeType.CustomerConsultant" />
}, {
  value: 'jmes:activity.creator',
  label: <FormattedMessage id="Workguide.Workflow.Form.EditNode.AssigneeType.Creator" />
}, {
  value: 'select',
  label: <FormattedMessage id="Workguide.Workflow.Form.EditNode.AssigneeType.SelectConsultant" />
}, {
  value: 'customJmes',
  label: <FormattedMessage id="Workguide.Workflow.Form.EditNode.AssigneeType.CustomJmes" />
}];

const completionDateTypeOptions = [{
  value: 'flex',
  label: <FormattedMessage id="Workguide.Workflow.Form.EditNode.CompletionDateType.Flex" />
}, {
  value: 'customJmes',
  label: <FormattedMessage id="Workguide.Workflow.Form.EditNode.CompletionDateType.CustomJmes" />
}];

class WorkflowTreeFormEditNodeGeneral extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      assigneeType: this.getAssigneeTypeFromFormData(),
      completionDateType: this.getCompletionDateTypeFromData()
    };

    this.onAssigneeValueChange = this.onAssigneeValueChange.bind(this);
    this.onAssigneeTypeChange = this.onAssigneeTypeChange.bind(this);
    this.onCompletionDateValueChange = this.onCompletionDateValueChange.bind(this);
    this.onCompletionDateTypeChange = this.onCompletionDateTypeChange.bind(this);
    this.onCommentValueChange = this.onCommentValueChange.bind(this);
    this.onCommentDefaultValueChange = this.onCommentDefaultValueChange.bind(this);
  }

  /**
   * Handle onChange of a value in the assignee object
   *
   * @param   {String}  key    Key of the value to set
   * @param   {Mixed}   value  Value to set
   *
   * @return  void
   */
  onAssigneeValueChange(key, value) {
    const {
      form,
      onValueChange
    } = this.props;

    const current = get(form, 'data.assigneeId', {});
    const updated = isNil(value)
      ? update(current, { $unset: [key] })
      : update(current, { [key]: { $set: value } });

    onValueChange('assigneeId', updated);
  }

  /**
   * Handle onChange of a value in the completion date object
   *
   * @param   {String}  key    Key of the value to set
   * @param   {Mixed}   value  Value to set
   *
   * @return  void
   */
  onCompletionDateValueChange(key, value) {
    const {
      form,
      onValueChange
    } = this.props;

    const current = get(form, 'data.completionDate', {});
    const updated = isNil(value)
      ? update(current, { $unset: [key] })
      : update(current, { [key]: { $set: value } });

    onValueChange('completionDate', updated);
  }

  /**
   * Handle onChange of a value in the comment object
   *
   * @param   {String}  key    Key of the value to set
   * @param   {Mixed}   value  Value to set
   *
   * @return  void
   */
  onCommentValueChange(key, value) {
    const {
      form,
      onValueChange
    } = this.props;

    const current = get(form, 'data.comment', {});
    const updated = isNil(value)
      ? update(current, { $unset: [key] })
      : update(current, { [key]: { $set: value } });

    onValueChange('comment', updated);
  }

  /**
   * Handle onChange of the default value in the comment object
   *
   * @param   {String}  key    Key of the value to set
   * @param   {Mixed}   value  Value to set
   *
   * @return  void
   */
  onCommentDefaultValueChange(key, value) {
    const {
      form,
      language,
      onValueChange
    } = this.props;

    const current = get(form, 'data.comment', {});
    const updated = isNil(value)
      ? update(current, { default: { $unset: [language] } })
      : update(current, { default: { [language]: { $set: value } } });

    onValueChange('comment', updated);
  }

  /**
   * Set the assignee type from button select
   *
   * @param   {String}  key     Form element id
   * @param   {Array}   values  Selected types from button select
   *
   * @return  void
   */
  onAssigneeTypeChange(key, values) {
    const value = first(values);
    this.setState({ assigneeType: value });

    if (value.startsWith('jmes:')) {
      this.onAssigneeValueChange('default', value);
    } else {
      this.onAssigneeValueChange('default');
    }
  }

  /**
   * Set the competion date type from button select
   *
   * @param   {String}  key     Form element id
   * @param   {Array}   values  Selected types from button select
   *
   * @return  void
   */
  onCompletionDateTypeChange(key, values) {
    const value = first(values);
    this.setState({ completionDateType: value });
  }

  /**
   * Get the assignee type from current form data
   *
   * @return  {String} assigneeType Assigne type
   */
  getAssigneeTypeFromFormData() {
    const { form } = this.props;

    const assigneeId = get(form, 'data.assigneeId.default');
    if (isNil(assigneeId)) {
      return undefined;
    }

    const option = assigneeTypeOptions.find((o) => o.value === assigneeId);
    if (!isNil(option)) {
      return assigneeId;
    }

    if (assigneeId.startsWith('jmes:')) {
      return 'jmesCustom';
    }

    return 'select';
  }

  /**
   * Get the completion date type from current form data
   *
   * @return  {String} completionDateType Completion date type
   */
  getCompletionDateTypeFromData() {
    const { form } = this.props;

    const completionDate = get(form, 'data.completionDate.default');
    if (isNil(completionDate)) {
      return undefined;
    }

    if (completionDate.startsWith('flex')) {
      return 'flex';
    }

    return 'customJmes';
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      assigneeType,
      completionDateType
    } = this.state;
    const {
      consultants,
      form,
      language,
      onTranslatableValueChange,
      onValueChange,
      validations
    } = this.props;

    const assignee = consultants.find((c) => c.id === get(form, 'data.assigneeId.default'));
    const isInvalid = Object
      .keys(validations)
      .some((key) => ['title', 'description', 'assigneeId', 'completionDate', 'comment'].includes(key));

    return (
      <Collapsible
        className={cl({
          'workflow-tree-form-edit-node--collapsible': true,
          'workflow-tree-form-edit-node--collapsible--invalid': isInvalid,
          'workflow-tree-form-edit-node--general': true
        })}
        isCollapsed={false}
        label={<FormattedMessage id="Workguide.Workflow.Form.EditNode.Title.General" />}
      >
        <Text
          id="id"
          disabled
          onChange={noop}
          value={get(form, 'data.id')}
        />

        <Text
          id="title"
          onChange={onTranslatableValueChange}
          value={get(form, `data.title.${language}`)}
          validations={get(validations, `title.${language}`)}
        />

        <Description
          id="description"
          onChange={onTranslatableValueChange}
          value={get(form, `data.description.${language}`)}
          validations={get(validations, `description.${language}`)}
        />

        <div style={{ paddingBottom: '1em' }} />

        {/* Assignee ID */}
        <Label>
          <FormattedMessage id="Workguide.Workflow.Form.EditNode.AssigneeType.Title" />
        </Label>

        <ButtonSelect
          id="assigneeType"
          onChange={this.onAssigneeTypeChange}
          options={assigneeTypeOptions}
          value={[assigneeType]}
        />

        {assigneeType === 'select' && (
          <ConsultantSelect
            id="default"
            consultants={consultants}
            onChange={this.onAssigneeValueChange}
            returnValue="consultant.id"
            showLabel={false}
            value={assignee}
            validations={get(validations, 'assignee.default')}
          />
        )}

        {assigneeType === 'customJmes' && (
          <CustomJmesInput
            id="default"
            onChange={this.onAssigneeValueChange}
            prefix="jmes:"
            showLabel={false}
            value={get(form, 'data.assigneeId.default')}
            validations={get(validations, 'assignee.default')}
          />
        )}

        <div className="workflow-tree-form-edit-node--general--assigneeId-options">
          <Toggle
            id="disabled"
            onChange={this.onAssigneeValueChange}
            value={get(form, 'data.assigneeId.disabled', false)}
            validations={get(validations, 'assignee.disabled')}
          />

          <div style={{ paddingRight: '2em' }} />

          <Toggle
            id="hidden"
            onChange={this.onAssigneeValueChange}
            value={get(form, 'data.assigneeId.hidden', false)}
            validations={get(validations, 'assignee.hidden')}
          />
        </div>

        {/* Completion date */}
        <Label>
          <FormattedMessage id="Workguide.Workflow.Form.EditNode.CompletionDateType.Title" />
        </Label>

        <ButtonSelect
          id="completionDateType"
          onChange={this.onCompletionDateTypeChange}
          options={completionDateTypeOptions}
          value={[completionDateType]}
        />

        {completionDateType === 'flex' && (
          <DateField
            id="default"
            onChange={this.onCompletionDateValueChange}
            showLabel={false}
            value={get(form, 'data.completionDate.default')}
            validations={get(validations, 'completionDate.default')}
          />
        )}

        {completionDateType === 'customJmes' && (
          <CustomJmesInput
            id="default"
            onChange={this.onCompletionDateValueChange}
            prefix="jmes:"
            showLabel={false}
            value={get(form, 'data.completionDate.default')}
            validations={get(validations, 'completionDate.default')}
          />
        )}

        <div className="workflow-tree-form-edit-node--general--completionDate-options">
          <Toggle
            id="required"
            onChange={this.onCompletionDateValueChange}
            value={get(form, 'data.completionDate.required', false)}
            validations={get(validations, 'completionDate.required')}
          />

          <div style={{ paddingRight: '2em' }} />

          <Toggle
            id="disabled"
            onChange={this.onCompletionDateValueChange}
            value={get(form, 'data.completionDate.disabled', false)}
            validations={get(validations, 'completionDate.disabled')}
          />

          <div style={{ paddingRight: '2em' }} />

          <Toggle
            id="hidden"
            onChange={this.onCompletionDateValueChange}
            value={get(form, 'data.completionDate.hidden', false)}
            validations={get(validations, 'completionDate.hidden')}
          />
        </div>

        {/* Comment */}
        <Label>
          <FormattedMessage id="Workguide.Workflow.Form.EditNode.Comment.Title" />
        </Label>

        <Comment
          id="default"
          onChange={this.onCommentDefaultValueChange}
          value={get(form, `data.comment.default.${language}`)}
          validations={get(validations, `comment.default.${language}`)}
        />

        <div className="workflow-tree-form-edit-node--general--completionDate-options">
          <Toggle
            id="required"
            onChange={this.onCommentValueChange}
            value={get(form, 'data.comment.required', false)}
            validations={get(validations, 'comment.required')}
          />

          <div style={{ paddingRight: '2em' }} />

          <Toggle
            id="disabled"
            onChange={this.onCommentValueChange}
            value={get(form, 'data.comment.disabled', false)}
            validations={get(validations, 'comment.disabled')}
          />

          <div style={{ paddingRight: '2em' }} />

          <Toggle
            id="hidden"
            onChange={this.onCommentValueChange}
            value={get(form, 'data.comment.hidden', false)}
            validations={get(validations, 'comment.hidden',)}
          />
        </div>

        <div style={{ paddingBottom: '1em' }} />

        <WorkguidePermissions
          id="permissions"
          onChange={onValueChange}
          value={get(form, 'data.permissions')}
        />

        <div style={{ paddingBottom: '1em' }} />
      </Collapsible>
    );
  }
}

WorkflowTreeFormEditNodeGeneral.propTypes = {
  consultants: PropTypes.array,
  form: PropTypes.object,
  language: PropTypes.string,
  onValueChange: PropTypes.func,
  onTranslatableValueChange: PropTypes.func,
  validations: PropTypes.object
};

WorkflowTreeFormEditNodeGeneral.defaultProps = {
  consultants: [],
  form: {},
  language: 'de',
  onValueChange: noop,
  onTranslatableValueChange: noop,
  validations: {}
};

export default WorkflowTreeFormEditNodeGeneral;
