import React from 'react';
import PropTypes from 'prop-types';
import { has, get, noop, isUndefined, debounce } from 'lodash';
import { FormGroup, ControlLabel, Col, FormControl } from 'react-bootstrap';
import Toggle from 'react-toggle';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';

class UploadFile extends React.PureComponent {
  constructor(props) {
    super(props);

    const { id, fetchCodes, editValueField } = props;

    this.state = {
      filename: undefined
    };

    fetchCodes('mimeType');

    // Set default validation
    editValueField(id, 'validations', {
      meta: {
        type: 'object',
        validations: {
          filename: {
            type: 'string',
            required: true
          },
        }
      }
    });

    // Set hideValidations to true. Component will render validations itself
    editValueField(id, 'hideValidations', true);

    this.toggleMulti = this.toggleMulti.bind(this);
    this.onMimeTypesChange = this.onMimeTypesChange.bind(this);
    this.onFilenameChange = this.onFilenameChange.bind(this);
    this.toggleEmailAttachments = this.toggleEmailAttachments.bind(this);
  }

  /**
   * Toggle the multi flag.
   *
   * @return void
   */
  toggleMulti() {
    const { id, editValueField } = this.props;
    debounce(editValueField, 500)(id, 'multi');
  }

  /**
   * Toggle the emailAttachments flag.
   *
   * @return void
   */
   toggleEmailAttachments() {
    const { id, editValueField } = this.props;
    debounce(editValueField, 500)(id, 'emailAttachments');
  }

  /**
   * Handle mime types change
   *
   * @param  {Array} selected Array of allowed mime types
   *
   * @return void
   */
  onMimeTypesChange(selected) {
    const { id, editValueField} = this.props;
    const value = isUndefined(selected)
      ? undefined
      : selected.map(s => s.value);

    editValueField(id, 'allowedMimeTypes', value);
  }


  /**
   * Handle filename change
   *
   * @return {[type]} [description]
   */
  onFilenameChange(ev) {
    const { id, item, editValueField } = this.props;

    let value = get(ev, 'target.value', '');
    value = value.trim().length > 0 ? value : undefined;

    const filename = get(item, 'props.filename');
    const fn = !isUndefined(value)
      ? { value, editable: false }
      : undefined;

    editValueField(id, 'filename', fn);
  }

  render() {
    const {
      item,
      codes,
      language

    } = this.props;

    return (
      <React.Fragment>
        <FormGroup controlId="codeGroupMulti">
          <Col componentClass={ControlLabel} sm={3}>
            <FormattedMessage id="Workguide.Configuration.Common.MultiSelect" />
          </Col>
          <Col sm={9} style={{ paddingTop: '10px' }}>
          <Toggle
              checked={get(item, 'props.multi')===undefined?false:item.props.multi}
              onChange={this.toggleMulti}
          />
          </Col>
        </FormGroup>
        <FormGroup controlId="codeGroupMulti">
          <Col componentClass={ControlLabel} sm={3}>
            Datein im Mail anhängen
          </Col>
          <Col sm={9} style={{ paddingTop: '10px' }}>
          <Toggle
              checked={get(item, 'props.emailAttachments')===undefined?false:item.props.emailAttachments}
              onChange={this.toggleEmailAttachments}
          />
          </Col>
        </FormGroup>

        <FormGroup controlId="codeGroupMulti">
          <Col componentClass={ControlLabel} sm={3}>
            Erlaubte Dateitypen
          </Col>
          <Col sm={9}>
          <Select
            value={get(item, 'props.allowedMimeTypes', [])}
            options={get(codes, 'mimeType', []).map(c => ({ value: c.number, label: get(c, `text.${language}`) }))}
            onChange={this.onMimeTypesChange}
            multi={true}
          />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Dokumentname Standard Wert
          </Col>

          <Col sm={4}>
            <FormControl
              type="text"
              value={get(item, 'props.filename.value', '')}
              onChange={this.onFilenameChange}
            />
          </Col>
        </FormGroup>

      </React.Fragment>
    )
  }
}


UploadFile.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  item: PropTypes.object.isRequired,
  codeGroups: PropTypes.array,
  codes: PropTypes.object,
  fetchCodes: PropTypes.func,
  editValueField: PropTypes.func,
  language: PropTypes.string
};

UploadFile.defaultProps = {
  editValueField: noop,
  codeGroups: [],
  codes: {},
  fetchCodes: noop,
  language: 'de'
};

export default UploadFile;
