import IsLeInput from '../configuration/isLeInput';

export default {
  key: 'isLe',
  label: 'Maximalwert',
  validation: 'isLe',
  availableFor: ['Input'],
  ConfigurationForm: IsLeInput,
  get: function(params) {
    return { isLe: params };
  }
}
