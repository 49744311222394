import React from 'react';
import PropTypes from 'prop-types';
import { get, map, reduce, every, debounce, noop, flatten, uniq } from 'lodash';
import { Col, FormGroup, ControlLabel } from 'react-bootstrap';
import Toggle from 'react-toggle';
import Select from 'react-virtualized-select';
import { FormattedMessage } from 'react-intl';

const shortcuts = {
  domicile: {
    label: 'Domizil Adresse',
    codes: ['addressType-11']
  },
  secret: {
    label: 'Geheimadresse',
    codes: ['addressType-17']
  },
  shipping: {
    label: 'Versandadresse',
    codes: ['addressType-2']
  },
  additional: {
    label: 'Zusatzadressen',
    codes: [
      'addressType-21',
      'addressType-22',
      'addressType-24',
      'addressType-25',
      'addressType-26',
      'addressType-27',
      'addressType-28',
      'addressType-29',
      'addressType-31',
      'addressType-32',
      'addressType-34',
      'addressType-35',
      'addressType-36',
      'addressType-37',
      'addressType-38',
      'addressType-39',
      'addressType-41',
      'addressType-42',
      'addressType-44',
      'addressType-45',
      'addressType-46',
      'addressType-47',
      'addressType-48',
      'addressType-49',
      'addressType-51',
      'addressType-52',
      'addressType-54',
      'addressType-55',
      'addressType-56',
      'addressType-57',
      'addressType-58',
      'addressType-59',
      'addressType-61',
      'addressType-62',
      'addressType-64',
      'addressType-65',
      'addressType-66',
      'addressType-67',
      'addressType-68',
      'addressType-71'
    ]
  }
};

class CustomerAddressesConfiguration extends React.Component {
  constructor(props) {
    super(props);

    const { item, fetchCodes } = props;

    this.state = {
      selectedShortcuts: reduce(shortcuts, (result, shortcut, key) => {
        if (every(shortcut.codes, (c => get(item, 'props.defaultFilter', []).includes(c)))) result.push(key);
        return result;
      }, [])
    };

    fetchCodes('addressType');

    this.toggleMulti = this.toggleMulti.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onShortcutChange = this.onShortcutChange.bind(this);
  }

  /**
   * Handle change of type select
   *
   * @param  {Array} selected Selected values
   *
   * @return void
   */
  onTypeChange(selected) {
    const { editValueField } = this.props;

    editValueField(get(this, 'props.id'), 'defaultFilter', selected.map(s => s.value));
  }

  /**
   * Handle shortcut change.
   * Add / remove address types from array
   *
   * @param  {[type]} id [description]
   *
   * @return void
   */
  onShortcutChange(id) {
    const { item, editValueField } = this.props;
    const { selectedShortcuts } = this.state;
    // Filter out types that are not part of the currently selected shortcuts to be able to add them again
    const currentShortcutCodes = flatten(selectedShortcuts.map(s => get(shortcuts, `${s}.codes`)));
    const currentDefaultFilter = get(item, 'props.defaultFilter', []);
    const additionalTypes = currentDefaultFilter.filter(c => !currentShortcutCodes.includes(c));

    const updated = [ ...selectedShortcuts ];
    updated.includes(id)
      ? updated.splice(updated.findIndex(s => s === id), 1)
      : updated.push(id);

    this.setState({ selectedShortcuts: updated });
    const addressTypes = [ ...flatten(updated.map(c => get(shortcuts, `${c}.codes`))), ...additionalTypes ];

    editValueField(get(this, 'props.id'), 'defaultFilter', uniq(addressTypes));
  }

  /**
   * Toggle the multi flag.
   * Reset defauls and debounce execution
   *
   * @return void
   */
  toggleMulti() {
    const { id, editValueField } = this.props;
    // Reset defaults if multi changes
    editValueField(id, 'defaultValue', null);
    debounce(editValueField, 500)(id, 'multi');
  }

  /**
   * Render the shortcuts
   *
   * @return {ReactElement} markup
   */
  renderShortcuts() {
    const { selectedShortcuts } = this.state;

    return map(shortcuts, (s, key) => (
      <Col sm={2} key={key}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ paddingRight: '5px' }}>
            <Toggle
              checked={selectedShortcuts.includes(key)}
              onChange={() => this.onShortcutChange(key)}
            />
          </div>
          <div>{s.label}</div>
        </div>
      </Col>
    ));
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { item, codes, language } = this.props;

    const typeOptions = get(codes, 'addressType', []).map(c =>({
      value: c.id,
      label: get(c, `text.${language}`)
    }));

    return(
      <React.Fragment>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            <FormattedMessage id="Workguide.Configuration.Common.MultiSelect" />
          </Col>
          <Col sm={9} style={{ paddingTop: '10px' }}>
          <Toggle
              checked={get(item, 'props.multi')===undefined?false:item.props.multi}
              onChange={this.toggleMulti}
          />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Shortcuts
          </Col>
          <Col sm={9} style={{ padding: '10px 0px 0px 0px' }}>
            {this.renderShortcuts()}
          </Col>
        </FormGroup>


        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Adresstypen
          </Col>
          <Col sm={9}>
          <Select
            options={typeOptions}
            multi={true}
            value={get(item, 'props.defaultFilter', [])}
            onChange={this.onTypeChange}
          />
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
}

CustomerAddressesConfiguration.propTypes = {
  item: PropTypes.object.isRequired,
  codes: PropTypes.object,
  language: PropTypes.string,
  editValueField: PropTypes.func
};

CustomerAddressesConfiguration.defaultProps = {
  editValueField: noop,
  codes: { addressType: [] },
  language: 'de'
};

export default CustomerAddressesConfiguration;
