import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { get } from 'lodash';
import PageContainer from '../Common/PageContainer';
import DefinitionJobs from './DefinitionJobs';
import ExecutedJobs from './ExecutedJobs';
import TriggerJobs from './TriggerJobs';

function JobProcessingComponent({
  definitions,
  executed,
  executionHistory,
  intl,
  jobProcessingActions,
  language,
  summary,
  triggerJob
}) {
  return (
    <div>
      <PageContainer
        title={<FormattedMessage id="jobProcessing_title" />}
        requesting={get(definitions, 'requesting', false)}
        isTransparentLoader
      >
        <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
          <Tab eventKey={1} title={intl.formatMessage({ id: 'jobProcessing_definition_jobs' })}>
            <DefinitionJobs
              definitions={definitions}
              jobProcessingActions={jobProcessingActions}
              language={language}
            />
          </Tab>

          <Tab eventKey={2} title={intl.formatMessage({ id: 'jobProcessing_executed_jobs' })}>
            <ExecutedJobs
              definitions={definitions}
              executed={executed}
              executionHistory={executionHistory}
              intl={intl}
              language={language}
              jobProcessingActions={jobProcessingActions}
              summary={summary}
            />
          </Tab>

          <Tab eventKey={3} title={intl.formatMessage({ id: 'jobProcessing_start_jobs' })}>
            <TriggerJobs
              definitions={definitions}
              form="triggerJobsForm"
              jobProcessingActions={jobProcessingActions}
              triggerJob={triggerJob}
            />
          </Tab>
        </Tabs>
      </PageContainer>
    </div>
  );
}

export const JobProcessing = injectIntl(JobProcessingComponent);

JobProcessingComponent.propTypes = {
  definitions: PropTypes.object,
  executed: PropTypes.object,
  executionHistory: PropTypes.object,
  intl: PropTypes.object.isRequired,
  jobProcessingActions: PropTypes.object.isRequired,
  summary: PropTypes.object,
  triggerJob: PropTypes.object
};

JobProcessingComponent.defaultProps = {
  definitions: {},
  executed: {},
  executionHistory: {},
  summary: {},
  triggerJob: {}
};
