import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, noop, reduce } from 'lodash';

import { ActionConfigurationServiceCall } from '../../../../packages/Workguide/index';

/**
 * This class is a facade to the new ServiceCallConfiguration from workguide package
 */
class ServiceCallConfig extends React.Component {
  constructor(props) {
    super(props);

    this.onJmesParamChange = this.onJmesParamChange.bind(this);
    this.onActionChange = this.onActionChange.bind(this);
  }

  /**
   * Handle jmesParam change.
   *
   * @param   {String}  key    Key in mapping
   * @param   {String}  value  Mapping value
   *
   * @return  void
   */
  onJmesParamChange(key, value = {}) {
    const { onChange } = this.props;

    // We only care about mapping
    if (key === 'mapping') {
      const mapping = reduce(value, (result, v, k) => {
        return [
          ...result,
          {
            key: k,
            value: v
          }
        ];
      }, []);

      onChange('mapping', mapping);
    }
  }

  /**
   * Handle change of the whole action object
   *
   * @param {Object} action Action object
   *
   * @return  void
   */
  onActionChange({ action }) {
    const { onChangeMany } = this.props;

    const { url, method, jmesParams } = action;

    const mapping = reduce(get(jmesParams, 'mapping', {}), (result, v, k) => {
      return [
        ...result,
        {
          key: k,
          value: v
        }
      ];
    }, []);

    onChangeMany({
      url,
      method,
      mapping
    });
  }

  /**
   * Create new data structure from legacy params
   *
   * @return  {Object} action New action
   */
  prepareAction() {
    const { params } = this.props;

    const action = {
      url: `/${params.url}/`.replaceAll('//', '/'),
      method: params.method,
      eventPolling: params.eventPolling,
      jmesParams: {
        mapping: get(params, 'mapping', []).reduce((result, item) => ({ ...result, [item.key]: item.value }), {})
      }
    };

    return action;
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      edit,
      onChange
    } = this.props;

    const action = this.prepareAction();

    return (
      <div>
        <fieldset disabled={!edit}>
          <ActionConfigurationServiceCall
            action={action}
            onActionChange={this.onActionChange}
            onJmesParamChange={this.onJmesParamChange}
            onValueChange={onChange}
          />
        </fieldset>
      </div>
    );
  }
}

ServiceCallConfig.propTypes = {
  edit: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeMany: PropTypes.func,
  params: PropTypes.object
};

ServiceCallConfig.defaultProps = {
  edit: false,
  onChange: noop,
  onChangeMany: noop,
  params: {}
};

function mapStateToProps(state, ownProps) {
  return {

  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({}, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCallConfig);
