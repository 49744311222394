import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { FormGroup, ControlLabel, Col } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { FormattedMessage } from 'react-intl';

const CustomerContactConfiguration = React.memo(({
  editValueField,
  id,
  item
}) => {
  return (
    <FormGroup controlId="codeGroupMulti">
      <Col componentClass={ControlLabel} sm={3}>
        <FormattedMessage id="Workguide.Configuration.Common.MultiSelect" />
      </Col>
      <Col sm={9} style={{ paddingTop: '10px' }}>
        <Toggle
          checked={get(item, 'props.multi') === undefined ? false : item.props.multi}
          onChange={() => editValueField(id, 'multi')}
        />
      </Col>
    </FormGroup>
  );
});

CustomerContactConfiguration.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  item: PropTypes.object.isRequired,
  editValueField: PropTypes.func
};

CustomerContactConfiguration.defaultProps = {
  editValueField: noop
};

export default CustomerContactConfiguration;
