import axios from 'axios';
import { toast } from 'react-toastify';
import { encodeRqlString } from '@evoja-web/redux-saga-utils';
import { get } from 'lodash';

function solrQuery(filter, limit, offset) {
  const results = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const key in filter) {
    // eslint-disable-next-line no-prototype-builtins
    if (filter.hasOwnProperty(key)) {
      const isBlank = filter[key].type === 'blank';
      if (isBlank) {
        results.push(`${key}:"NULL"`);
      } else if (filter[key].type === 'match') {
        const str = `${key}:"${filter[key].filter}"`;
        results.push(str);
      } else {
        const str = `${key}:${filter[key].filter}`;
        results.push(str);
      }
    }
  }

  if (results.length === 0) {
    return `?limit(${limit},${offset})`;
  }

  return `?search(${encodeRqlString(results.join(' '))})&limit(${limit},${offset})`;
}

export const fetchTranslations = async (filter, limit, offset) => {
  const query = solrQuery(filter, limit, offset);
  try {
    const response = await axios.get(`/work/translation/${query}`);
    return response.data;
  } catch (error) {
    toast.error('Translations load failed', {
      type: 'error',
    });
    throw error;
  }
};

export const updateTranslations = async (updatedTranslations) => {
  try {
    // source be set when edited in admin ui, otherwiese the tim job will reset the entry to its inital state
    await axios.put(`/work/translation/${updatedTranslations.id}`, { ...updatedTranslations, source: 'AdminUI' });
    return toast.success('Translations update success', {
      type: 'success',
    });
  } catch (error) {
    toast.error('Translations update failed', {
      type: 'error',
    });
    return error;
  }
};

const convertToCSV = (entries) => {
  const header = [
    'id',
    'key',
    'Deutsch',
    'Französisch',
    'Applikation',
    'Namespace'
  ].join(';');

  const rows = entries.map((entry) => [
    get(entry, 'id'),
    get(entry, 'key'),
    get(entry, 'value.de'),
    get(entry, 'value.fr'),
    get(entry, 'application.name.de'),
    get(entry, 'namespace')
  ].join(';'));

  const content = new Blob([`\ufeff${[header, ...rows].join('\n')}`], { type: 'text/csv;charset=utf-8' });
  return content;
};

export const exportMissingTranslations = async () => {
  try {
    const response = await axios.get(`/work/translation/?search(${encodeRqlString('fr:NULL')})&limit(2000)`);
    const blob = convertToCSV(get(response, 'data', []));

    return blob;
  } catch (error) {
    throw new Error('Error generating csv', error);
  }
};
