const permissions = [{
  id: 'ALL_USERS',
  name: 'ALL_USERS',
  permissions: {
    Modules: {
      ShowAll: false,
      ParamTransfer: {
        Show: false
      },
      'adminv2-bankdocuments': {
        Show: false
      },
      'adminv2-externallinks': {
        Show: false
      },
      'adminv2-financingholidays': {
        Show: false
      },
      'adminv2-textblocks': {
        Show: false
      },
      'adminv2-translations': {
        Show: false
      },
      'adminv2-usertracking': {
        Show: false
      },
      'adminv2-code-mapping': {
        Show: false
      },
      'adminv2-job-processing': {
        Show: false
      },
      'adminv2-eventstatus': {
        Show: false
      },
      'adminv2-email-subscriptions': {
        Show: false
      },
      'adminv2-set-management': {
        Show: false
      },
      'adminv2-workguide': {
        Show: false
      },
      'adminv2-leadqualification': {
        Show: false
      },
      'adminv2-customerbook': {
        Show: false
      },
      'adminv2-interests': {
        Show: false
      },
      'adminv2-leads-change': {
        Show: false
      },
      'adminv2-notice-import': {
        Show: false
      },
      'adminv2-leadswitch': {
        Show: false
      },
      'adminv2-uinotes': {
        Show: false
      },
      'adminv2-bulkloads': {
        Show: false
      },
      'adminv2-ref-errors': {
        Show: false
      },
      'adminv2-analytics': {
        Show: false
      },
      'adminv2-analytics-logs': {
        Show: false
      },
      'adminv2-api-client': {
        Show: false
      },
      'adminv2-replay-auditlog': {
        Show: false
      },
      'adminv2-featureflags': {
        Show: false
      },
    }
  }
}, {
  id: 'TRADUCTION',
  name: 'TRADUCTION',
  permissions: {
    Modules: {
      ShowAll: false,
      'adminv2-workguide': {
        Show: true
      },
      'adminv2-featureflags': {
        Show: false
      },
    }
  }
}, {
  id: 'NEWSLETTER',
  name: 'NEWSLETTER',
  permissions: {
    Modules: {
      ShowAll: false,
      'adminv2-email-subscriptions': {
        Show: true
      },
      'adminv2-featureflags': {
        Show: false
      },
    }
  }
}, {
  id: 'LEADMANAGEMENT',
  name: 'LEADMANAGEMENT',
  permissions: {
    Modules: {
      ShowAll: false,
      'adminv2-leadqualification': {
        Show: true
      },
      'adminv2-leads-change': {
        Show: true
      },
      'adminv2-leadswitch': {
        Show: true
      },
      'adminv2-featureflags': {
        Show: false
      },
    }
  }
}, {
  id: 'ADMINISTRATOR',
  name: 'ADMINISTRATOR',
  permissions: {
    Modules: {
      ShowAll: true
    }
  }
}];

export default permissions;
