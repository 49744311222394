import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { arrayMove } from 'react-sortable-hoc';
import update from 'immutability-helper';
import _ from 'lodash';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';

import EditContainer from '../components/EditContainer';
import * as InvestmentProductActions from '../actions/InvestmentProductActions';

import { createRef, getHostName, getIdFromRef, handleGravitonError } from '../utils'

import Items from '../components/InvestmentProduct/Items';
import { EndpointName } from '../constants/EndpointName';

const DOCUMENT_FIELDS = ['investmentDocument', 'productBasicInformation'];

class InvestmentProduct extends React.Component {
  constructor(props) {
    super(props);
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.editEventField = this.editEventField.bind(this);
    this.editValueField = this.editValueField.bind(this);
    this.saveData = this.saveData.bind(this);

    props.actions.getInvestmentProductsRequest();
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { products, actions, productsToUpdate } = this.props;
    const newProducts = update(products, {
      $set: arrayMove(products, oldIndex, newIndex).map((product, index) => ({...product, order: index * 100}))
    });
    const reorderedProductsToUpdate = _.uniq([...productsToUpdate, ...newProducts.map((product) => product.id)]);
    actions.setData({ key: 'products', value: newProducts });
    actions.setData({ key: "productsToUpdate", value: reorderedProductsToUpdate });
  };

  removeFromServiceSubTypes(productIndex, serviceSubTypes) {
    const { products } = this.props;
    let newServiceSubTypes = serviceSubTypes;
    //remove the selected product from all strategies
    _.forEach(newServiceSubTypes, (subType, i) => {
      _.forEach(subType.productsPerStrategy, (ppS, ii) => {
        newServiceSubTypes = update(newServiceSubTypes, {
          [i]: { productsPerStrategy:
            {
              [ii]: {
                product: {
                  $apply: (value) => _.filter(value, (o) => !_.endsWith(o.$ref,products[productIndex].id))
                }
              }
            }
          }
        });
      });
    });
    return newServiceSubTypes;
  }

  add() {
    const { actions, products, productsToUpdate } = this.props;
    const newIndex = (products.length + 1) * 100;
    const id = v4();
    const newItem = {
      id: id,
      name: {
        de: '',
        en: '',
        fr: '',
      },
      productType: "saving",
      maxValue: 9999999999,
      order: newIndex,
    };
    // use concat instead of push for immutabillity
    const newProducts = update(products, {
      $push: [newItem]
    });
    actions.setData({ key: "products", value: _.sortBy(newProducts, o => o.order) });
    actions.setData({ key: "productsToUpdate", value: update(productsToUpdate, { $push: [id] }) })
  }

  // handle CRUD operations on the banklist modules/items
  remove(index) {
    const { products, serviceSubTypes, investmentDocuments, actions, productsToDelete, productsToUpdate } = this.props;

    //remove the selected product from all strategies
    const newServiceSubTypes = this.removeFromServiceSubTypes(index, serviceSubTypes);
    actions.setData({ key: 'serviceSubTypes', value: newServiceSubTypes });

    //remove all related documents to product
    const documentIds = DOCUMENT_FIELDS
      .reduce((result, field) => products[index][field] && products[index][field].$ref
        ? [...result, getIdFromRef(products[index][field].$ref)]
        : result,
      []);

    if (documentIds.length) {
      actions.setData({
        key: 'investmentDocuments',
        value: investmentDocuments.filter((document) => !documentIds.includes(document.id)),
      });
    }

    //remove Product
    const newProducts = products.filter((product, i) => i !== index);
    actions.setData({key: 'products', value: newProducts});

    if (_.indexOf(productsToUpdate, products[index].id) > -1) {
      //handle the case when a product is added and then just removed agaion without pressing save
      actions.setData({ key: 'productsToUpdate', value: productsToUpdate.filter((product) => product !== products[index].id) })
    } else {
      actions.setData({ key: 'productsToDelete', value: update(productsToDelete, { $push: [products[index]] }) });
    }
  }

  editEventField(element, id, event) {
    const { products, language, actions, productsToUpdate } = this.props;
    let newProducts = [];
    if (DOCUMENT_FIELDS.includes(element)) {
      this.onChangeDocumentUrl(event.target.value, id, element);
    } else {
      switch (element) {
        // handle name fields
        case 'name':
          if (event.target.value.length <= 40) {
            newProducts = update(products, {
              [id]: { name: { [language]: { $set: event.target.value } } },
            });

            // english is the main language in graviton. make sure english has always a value
            if (language !== 'en') {
              newProducts = update(newProducts, {
                [id]: { name: { en: { $set: event.target.value } } },
              });
            }
          } else {
            newProducts = products;
          }
          break;
        case 'knowledgeExperience':
          newProducts = update(products, {
            [id]: { [element]: { $set: event ? event.value : undefined } }
          });
          break;
        case 'productType':
        case 'variant':
        case 'valorNumber':
          newProducts = update(products, {
            [id]: { [element]: { $set: event.target.value }}
          });
          break;
        case 'minValue':
        case 'maxValue':
        const pattern = /^\d*$/;
        if (pattern.test(event.target.value)) {
          newProducts = update(products, {
            [id]: { [element]: { $set: _.parseInt(event.target.value) }}
          });
        } else {
          newProducts = products;
        }
          break;
        // handle toggles
        case 'hasNote':
          newProducts = update(products, {
            [id]: { [element]: { $set: !products[id][element] } },
          });
          break;
        default:
          newProducts = products;
      }
      actions.setData({ key: "products", value: newProducts });
    }

    if (_.indexOf(productsToUpdate, products[id].id) === -1) {
      actions.setData({
        key: "productsToUpdate",
        value: update(productsToUpdate, { $push: [products[id].id] }),
      });
    }
  }


  editValueField(value, element, id, relatedData=[]) {
    const { products, serviceSubTypes, productsToUpdate, actions } = this.props;
    let newServiceSubTypes = serviceSubTypes;
    // this is a ugly hack. But i could not figure out quickly a way to get the api-ref address from graviton
    // so this is always available here and i can read the address
    const refHostName = serviceSubTypes[0].productsPerStrategy[0].strategy
      ? getHostName(serviceSubTypes[0].productsPerStrategy[0].strategy.$ref)
      : '';
    let targetPath = '';
    switch (element) {
      case 'serviceSubType':
      //remove the selected product from all strategies
      newServiceSubTypes = this.removeFromServiceSubTypes(id, newServiceSubTypes);
        //apply the selected product to the new strategies
        const subTypeId = _.findIndex(serviceSubTypes, { id: value.value });
        _.forEach(relatedData, (ppS) => {
          const strategyId = _.findIndex(serviceSubTypes[subTypeId].productsPerStrategy, (s) => _.endsWith(s.strategy.$ref, ppS.id));
          newServiceSubTypes = update(newServiceSubTypes, {
            [subTypeId]: { productsPerStrategy:
              {
                [strategyId]: {
                  product: {
                    $push: [{ $ref: `${refHostName}/investment/product/${products[id].id}` }]
                  }
                }
              }
            }
          });
        })
        break;
      case 'strategy':
        const relatedSubTypeId = relatedData.length > 0 ? _.findIndex(serviceSubTypes, { id: relatedData[0].id }) : 0;

        //remove all Products from related service sub Types
        _.forEach(serviceSubTypes[relatedSubTypeId].productsPerStrategy, (ppS, ii) => {
          newServiceSubTypes = update(newServiceSubTypes, {
            [relatedSubTypeId]: { productsPerStrategy:
              {
                [ii]: {
                  product: {
                    $apply: (value) => _.filter(value, (o) => !_.endsWith(o.$ref,products[id].id))
                  }
                }
              }
            }
          });
        });

        // set the product to every selected strategy
        _.forEach(value, (v) => {
          const strategyId = _.findIndex(serviceSubTypes[relatedSubTypeId].productsPerStrategy, (s) => _.endsWith(s.strategy.$ref, v.value))
          newServiceSubTypes = update(newServiceSubTypes, {
            [relatedSubTypeId]: { productsPerStrategy:
              {
                [strategyId]: {
                  product: {
                    $push: [{ $ref: `${refHostName}/investment/product/${products[id].id}` }]
                  }
                }
              }
            }
          });
        });
      break;
    case 'assetClass':
    case 'assetAllocation':
    case 'assetSegment':
        switch(element) {
          case 'assetClass':
            targetPath = 'investment/assetclass';
            break;
          case 'assetAllocation':
            targetPath = 'investment/assetallocation'
            break;
          case 'assetSegment':
            targetPath = 'entity/code'
            break
          default:
            targetPath = ''
        }
      let newProducts = [];
      if (value === null) {
        newProducts = update(products, {
          [id]: { $unset: [`${element}`]}
        });
      } else {
        newProducts = update(products, {
          [id]: { [element]: { $set: { $ref: `${refHostName}/${targetPath}/${value.value}` }}}
        });
      }
      actions.setData({ key: "products", value: newProducts })
       _.indexOf(productsToUpdate, products[id].id) > -1 || actions.setData({ key: "productsToUpdate", value: update(productsToUpdate, { $push: [products[id].id] }) })
      break;

      default:
        console.error('element name not found')
    }
    actions.setData({ key: "serviceSubTypes", value: newServiceSubTypes });
  }

  // save the changes in ChecklistStore back to the graviton service
  saveData(event) {
    event.preventDefault();
    const {
      products,
      serviceSubTypes,
      investmentDocuments,
      initialInvestmentDocuments,
      productsToDelete,
      productsToUpdate,
      actions,
    } = this.props;
    actions.updateInvestmentProductsRequest({
      products,
      serviceSubTypes,
      investmentDocuments,
      initialInvestmentDocuments,
      productsToDelete,
      productsToUpdate,
    })
  }

  productsList() {
    const {
      products, language,
      knowledgeExperiences, assetAllocations, assetSegments, assetClasses,
      investmentStrategies, serviceSubTypes, investmentDocuments,
    } = this.props;

    return (
      <Items
        items={products}
        assetSegments={assetSegments}
        knowledgeExperiences={knowledgeExperiences}
        assetAllocations={assetAllocations}
        investmentDocuments={investmentDocuments}
        assetClasses={assetClasses}
        investmentStrategies={investmentStrategies}
        serviceSubTypes={serviceSubTypes}
        language={language}
        onAdd={this.add}
        onRemove={this.remove}
        editEventField={this.editEventField}
        editValueField={this.editValueField}
        onSortEnd={this.onSortEnd}
        useDragHandle
        useWindowAsScrollContainer
        helperClass="SortableHOCHelper"
      />
    );
  }

  render() {
    const {
      requesting, error, hasError, actions
    } = this.props;
    if (hasError) {
      toast.error(handleGravitonError(error));
      actions.setData({ key: "hasError", value: false });
    }

    return (
      <EditContainer
        requesting={requesting}
        saveData={this.saveData}
        title='Anlageprodukte'
        body={this.productsList()}
      />);
  }



  onChangeDocumentUrl(documentUrl, productIndex, field) {
    const documentIndex = this.getDocumentIndex(productIndex, field);
    if (!documentUrl) {
      this.removeDocumentUrl(productIndex, documentIndex, field);
      return;
    }

    if (documentIndex === -1) {
      this.addDocumentUrl(documentUrl, productIndex, field);
    } else {
      this.updateDocumentUrl(documentUrl, documentIndex);
    }
  }

  removeDocumentUrl(productIndex, documentIndex, field) {
    const { products, investmentDocuments, actions } = this.props;
    if (documentIndex !== -1) {
      actions.setData({
        key: "investmentDocuments",
        value: investmentDocuments.filter((document, index) => index !== documentIndex)
      });
    }
    actions.setData({
      key: "products",
      value: update(products, {
        [productIndex]: {
          $unset: [field],
        },
      }),
    });
  }

  updateDocumentUrl(documentUrl, documentIndex) {
    const { investmentDocuments, actions } = this.props;
    const updatedDocuments = update(investmentDocuments, {
      [documentIndex]: {
        documentType: {
          0: {
            documentSource: { $set: documentUrl },
          },
        },
      },
    });
    actions.setData({
      key: "investmentDocuments",
      value: updatedDocuments,
    });
  }

  addDocumentUrl(documentUrl, productIndex, field) {
    const { products, investmentDocuments, actions } = this.props;
    const documentId = v4();
    const updatedDocuments = update(investmentDocuments, {
      $push: [{
        id: documentId,
        documentType: [{
          documentTypeId: "PB",
          documentTypeName: "Produkt Beschreibung",
          documentSourceType: "URL",
          documentSource: documentUrl,
        }],
      }],
    });
    actions.setData({
      key: "investmentDocuments",
      value: updatedDocuments,
    });
    actions.setData({
      key: "products",
      value: update(products, {
        [productIndex]: {
          [field]: {
            $set: {
              $ref: createRef(EndpointName.INVESTMENT_DOCUMENT, documentId),
            },
          },
        },
      }),
    });
  }

  getDocumentIndex(productIndex, field) {
    const { products, investmentDocuments } = this.props;
    const documentId = products[productIndex][field] && products[productIndex][field].$ref
      ? getIdFromRef(products[productIndex][field].$ref)
      : undefined;
    return documentId
      ? _.findIndex(investmentDocuments, { id: documentId })
      : -1;
  }
}

function mapStateToProps(state) {
  return {
    products: state.investmentProduct.products,
    knowledgeExperiences: state.investmentProduct.knowledgeExperiences,
    assetSegments: state.investmentProduct.segments,
    assetAllocations: state.investmentProduct.allocations,
    assetClasses: state.investmentProduct.classes,
    investmentDocuments: state.investmentProduct.investmentDocuments,
    initialInvestmentDocuments: state.investmentProduct.initialInvestmentDocuments,
    investmentStrategies: state.investmentProduct.strategies,
    serviceSubTypes: state.investmentProduct.serviceSubTypes,
    removedItems: state.investmentProduct.removedItems,
    requesting: state.investmentProduct.requesting,
    language: state.login.language,
    error: state.investmentProduct.error,
    hasError: state.investmentProduct.hasError,
    productsToDelete: state.investmentProduct.productsToDelete,
    productsToUpdate: state.investmentProduct.productsToUpdate,
  }
}

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(InvestmentProductActions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentProduct);
