import React from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { arrayMove } from 'react-sortable-hoc';
import _ from 'lodash';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';

import { Banks } from '../components/Banklist/Banks';
import { handleGravitonError } from '../utils';
import PageContainer from '../components/Common/PageContainer';
import { SearchBlock } from '../components/Common/SearchBlock';
import { createStructuredSelector } from 'reselect';
import { getLanguage } from '../selectors/commonSelectors';
import {
  getBankListBanks,
  getBankListError,
  getBankListHasError,
  getBankListRemovedItems,
  getBankListRequesting,
  getBankListVisible,
} from '../selectors/bankListSelectors';
import { BANK_LIST_SAVE, getBanklistRequest, setData } from '../actions/BanklistActions';
import { ConfirmationDialog } from '../components/Common/ConfirmationDialog';

class Banklist extends React.Component {
  constructor(props) {
    super(props);
    this.edit = this.edit.bind(this);
    this.add = this.add.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.sort = this.sort.bind(this);
    this.remove = this.remove.bind(this);
    this.save = this.save.bind(this);
    this.confirmSave = this.confirmSave.bind(this);
    this.cancelSave = this.cancelSave.bind(this);

    this.state = {
      isConfirmationDialogOpen: false,
    };

    props.getBanklistRequest();
  }

  onSortEnd = ({ oldIndex: oldIndexVisible, newIndex: newIndexVisible }) => {
    const { banks, banksVisible, setData } = this.props;
    const oldIndex = banks.findIndex((it) => it === banksVisible[oldIndexVisible]);
    const newIndex = banks.findIndex((it) => it === banksVisible[newIndexVisible]);

    const sortedBanks = arrayMove(banks, oldIndex, newIndex)
      .map((bank, index) => ({
        ...bank,
        order: index * 100,
      }));
    setData({ key: 'list', value: sortedBanks });
  };

  sort() {
    const { banks, language, setData } = this.props;
    const sortedBanks = _.sortBy(banks, [(bank) => bank.text[language]])
      .map((bank, index) => ({
        ...bank,
        order: index * 100,
      }));
    setData({ key: 'list', value: sortedBanks });
  }

  // handle CRUD operations on the banklist modules/items
  add() {
    const { banks, setData } = this.props;
    const bankIndex = (banks.length + 1) * 100;
    const id = v4();
    const newItem = {
      recordOrigin: 'evoja',
      group: 'bankList',
      number: id,
      id: `bankList-${id}`,
      text: {
        de: '',
        en: '',
        fr: '',
      },
      textShort: {
        de: '',
        en: '',
        fr: '',
      },
      order: bankIndex,

    };
    // use concat instead of push for immutabillity
    const newBanks = banks.concat(newItem);
    setData({ key: 'list', value: _.sortBy(newBanks, o => o.order || 0) });
  }

  // handle CRUD operations on the banklist modules/items
  remove(item) {
    const { banks, removedItems, setData } = this.props;
    setData({ key: 'list', value: banks.filter((bank) => bank !== item) });
    setData({ key: 'removedItems', value: removedItems.concat(item.id) });
  }

  // handle CRUD operations on the banklist modules/items
  edit(item, type, event) {
    let { banks, language, setData } = this.props;
    const index = banks.findIndex((it) => it === item);

    if (type === 'textShort' && event.target.value.length <= 10) {
      banks = update(banks, {
        [index]: { textShort: { [language]: { $set: event.target.value } } },
      });

      // english is the main language in graviton. make sure english has always a value
      if (language !== 'en') {
        banks = update(banks, {
          [index]: { textShort: { en: { $set: event.target.value } } },
        });
      }
    } else if (type === 'text') {
      // fill textShort input when it was empty
      if (banks[index].textShort[language] === banks[index].text[language].substring(0, 10)) {
        banks = update(banks, {
          [index]: { textShort: { [language]: { $set: event.target.value } } },
        });
      }

      banks = update(banks, {
        [index]: { text: { [language]: { $set: event.target.value } } },
      });

      // english is the main language in graviton. make sure english has always a value
      if (language !== 'en') {
        if (banks[index].textShort.en === banks[index].text.en) {
          banks = update(banks, {
            [index]: { textShort: { en: { $set: event.target.value } } },
          });
        }
        banks = update(banks, {
          [index]: { text: { en: { $set: event.target.value } } },
        });
      }
    }
    setData({ key: 'list', value: banks });
  }

  // render the whole banklist
  bankview() {
    const { banksVisible, language } = this.props;

    return (
      <Banks
        banks={banksVisible}
        language={language}
        onAdd={this.add}
        onRemove={this.remove}
        onEdit={this.edit}
        onSortEnd={this.onSortEnd}
        onSort={this.sort}
      />
    );
  }

  save() {
    this.setState({ isConfirmationDialogOpen: true });
  }
  cancelSave() {
    this.setState({ isConfirmationDialogOpen: false });
  }
  confirmSave() {
    const { saveData } = this.props;
    this.setState({ isConfirmationDialogOpen: false }, saveData);
  }

  // embed the checklist in to the EditContainer component
  render() {
    const {
      requesting, language, hasError, error,
    } = this.props;
    if (hasError) {
      toast.error(handleGravitonError(error));
    }

    const { isConfirmationDialogOpen } = this.state;
    return (
      <PageContainer
        requesting={requesting}
        saveData={this.save}
        title={`Bankliste (${language})`}
        header={<SearchBlock/>}
      >
        {this.bankview()}
        <ConfirmationDialog
          isOpen={isConfirmationDialogOpen}
          confirm={this.confirmSave}
          cancel={this.cancelSave}
          title="Wollen Sie die Änderungen speichern?"
          content="Wollen Sie die Änderungen wirklich speichern?"
          textCancel="Abbrechen"
          textConfirm="Speichern"
        />
      </PageContainer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  banks: getBankListBanks,
  banksVisible: getBankListVisible,
  removedItems: getBankListRemovedItems,
  requesting: getBankListRequesting,
  language: getLanguage,
  hasError: getBankListHasError,
  error: getBankListError,
});
const mapDispatchToProps = {
  getBanklistRequest,
  setData,
  saveData: () => BANK_LIST_SAVE.request(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Banklist);
