import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isNil, isObject, isPlainObject } from 'lodash';

import DateValue from '../../Configuration/DateField/DateValue/DateValue';

class DateValidationConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  /**
   * Handle value change
   *
   * @param   {String}  value  Value from DateValue component
   *
   * @return  void
   */
  onChange(key, value) {
    const { onChange } = this.props;

    return isNil(value)
      ? onChange()
      : onChange([value]);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { params } = this.props;

    return (
      <DateValue
        id="validation"
        onChange={this.onChange}
        value={get(params, 0)}
      />
    );
  }
}

DateValidationConfiguration.propTypes = {
  onChange: PropTypes.func,
  params: PropTypes.array
};

DateValidationConfiguration.defaultProps = {
  onChange: noop,
  params: []
};

export default DateValidationConfiguration;
