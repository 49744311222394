import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { has, get, isNil, some } from 'lodash';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { LogoutContainer } from '@evoja-web/react-login';
import axios from 'axios';
import { BeatLoader } from 'react-spinners';
import { AclProvider } from '@evoja-web/client-acl';
import { ApplicationRelease } from '@evoja-web/react-application-release';
import { setLocale as setFormLocale } from '@evoja-web/react-form';

import '@evoja-web/react-application-release/dist/bundle.esm.css';
import { ConnectedIntlProvider } from './components/Common/ConnectedIntlProvider';
import Routes from './Routes';
import LoginContainer from './components/LoginContainer';
import isLocalhost from './utils/isLocalhost';
import environmentActions from './actions/Environment';
import store from './Store';
import permissions from './permissions';
import parseUrl from './utils/parseUrl';
import getLoginUrl from './utils/getLoginUrl';
import moduleActions from './packages/Module/actions/Actions';
import clientInfo from './clientInfo';

function onUnauthorized(error) {
  const ignorePathNames = ['/auth'];
  const statusCodes = [401, 403];
  const response = get(error, 'response');
  const { pathname = '' } = parseUrl(get(response, 'config.url'));

  if (
    !isNil(response)
    && statusCodes.includes(get(response, 'status'))
    && !some(ignorePathNames, (ignore) => pathname.startsWith(ignore))
  ) {
    const session = get(store.getState(), 'login.session');
    if (!isNil(session)) {
      window.location.replace(`/logout?redir=${window.location.pathname}`);
    }

    if (isNil(session)) {
      const url = getLoginUrl();
      window.location.replace(url);
    }
  }

  return Promise.reject(error);
}

function setAxiosDefaults(instance) {
  instance.defaults.headers.common = {};
  instance.defaults.timeout = 40000;
  instance.defaults.withCredentials = false;
  axios.interceptors.response.use((response) => response, onUnauthorized);

  return instance;
}

function renderLoader() {
  return (
    <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <BeatLoader />
    </div>
  );
}

class App extends React.Component {
  constructor(props) {
    super(props);

    const { environmentActions, environment, session } = props;
    environmentActions.configRequest();

    if (isLocalhost() && !isNil(session)) {
      axios.defaults.baseURL = get(environment, 'config.data.api_url');
      axios.defaults.headers.common['X-REST-Token'] = get(session, 'token');
    }

    setAxiosDefaults(axios);
  }

  componentDidMount() {
    const {
      language,
      moduleActions,
      session
    } = this.props;

    setFormLocale(`${language}-CH`);

    // Same does not work in constructor...
    if (!isNil(session) && has(session, 'token')) {
      axios.defaults.headers.common['X-REST-Token'] = get(session, 'token');
      // TODO: Temporarily fetch data here; otherwise, the isAllowed check in PrivateRoute.js will not work because modules are missing.
      // Make sure modules and categories are fetched for every page.
      moduleActions.categoriesRequest();
      moduleActions.modulesRequest();
    }
  }

  componentDidUpdate(prevProps) {
    const { session } = this.props;
    const { session: prevSession } = prevProps;

    if (!isNil(session) && isNil(prevSession) && has(session, 'token')) {
      axios.defaults.headers.common['X-REST-Token'] = get(session, 'token');
    }
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      environment,
      requesting,
      session
    } = this.props;

    if (requesting) {
      return renderLoader();
    }

    return (
      <div className="app">
        <ConnectedIntlProvider>
          <AclProvider
            permissions={permissions}
            userRoles={[...get(session, 'permission', []), 'ALL_USERS']}
            session={session}
          >
            <ApplicationRelease
              clientInfo={clientInfo}
              environment={get(environment, 'config.data')}
            >
              <BrowserRouter>
                <Switch>
                  <Route
                    exact
                    path="/login"
                    render={(props) => (
                      <LoginContainer
                        useXRestToken={false}
                        key="login"
                        {...props}
                        environment={{ config: get(environment, 'config.data') }}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/login/gateway"
                    render={(props) => (
                      <LoginContainer
                        useXRestToken={isLocalhost()}
                        key="gateway-login"
                        {...props}
                        environment={{ config: get(environment, 'config.data') }}
                        strategy="gateway"
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/logout"
                    render={(props) => (
                      <LogoutContainer
                        {...props}
                        gatewayLoginUrl={isLocalhost() ? '/login/gateway' : '/login'}
                        environment={{ config: get(environment, 'config.data') }}
                      />
                    )}
                  />

                  <Routes />
                </Switch>
              </BrowserRouter>
            </ApplicationRelease>
          </AclProvider>
        </ConnectedIntlProvider>
      </div>
    );
  }
}

App.propTypes = {
  environment: PropTypes.object,
  environmentActions: PropTypes.object.isRequired,
  language: PropTypes.string,
  moduleActions: PropTypes.object.isRequired,
  requesting: PropTypes.bool,
  session: PropTypes.object
};

App.defaultProps = {
  environment: undefined,
  language: 'de',
  requesting: false,
  session: undefined
};

function mapStateToProps(state) {
  const requesting = (
    !get(state, 'environment.config.fulfilled', false)
    || get(state, 'config.loading', false)
  );

  return {
    environment: state.environment,
    language: state.login.language,
    requesting,
    session: state.login.session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    environmentActions: bindActionCreators(environmentActions, dispatch),
    moduleActions: bindActionCreators(moduleActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
