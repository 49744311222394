/**
 * Possible operations
 *
 * @type {Object}
 */
export const operations = {
  eq: { value: 'eq', label: 'Entspricht' },
  contains: { value: 'contains', label: 'Beinhaltet' },
  gt: { value: 'gt', label: 'Grösser als' },
  lt: { value: 'lt', label: 'Kleiner als' },
  and: { value: 'and', label: 'UND Verknüpfung' },
  or: { value: 'or', label: 'ODER Verknüpfung' }
};

/**
 * Possible actions
 *
 * @type {Object}
 */
export const actions = {
  hide: { value: 'hide', label: 'Ausblenden' },
  show: { value: 'show', label: 'Einblenden' },
  disable: { value: 'disable', label: 'Sperren' },
  enable: { value: 'enable', label: 'Nicht gesperrt' },
  required: { value: 'required', label: 'Pflichtfeld' },
  optional: { value: 'optional', label: 'Kein Pflichtfeld' },
  filter: { value: 'filter', label: 'Werte einschränken' },
  filterExclude: { value: 'filterExclude', label: 'Werte exkludieren' },
  replace: { value: 'replace', label: 'Werte ersetzen' },
  postActionConditionFulfilled: { value: 'postActionConditionFulfilled', label: 'Post Action Bedingung erfüllt' },
  postActionConditionNotFulfilled: { value: 'postActionConditionNotFulfilled', label: 'Post Action Bedingung nicht erfüllt' },
  unset: { value: 'unset', label: 'Feldwert löschen' }
};

export const actionMap = {
  AccountSelect: [actions.hide, actions.show, actions.disable, actions.enable, actions.required, actions.optional, actions.filter, actions.filterExclude],
  ActivitySubStatus: [actions.hide, actions.show, actions.disable, actions.enable, actions.required, actions.optional, actions.filter, actions.filterExclude],
  AddressDogPlzSearch: [actions.hide, actions.show, actions.disable, actions.enable, actions.required, actions.optional],
  AddressDogSearch: [actions.hide, actions.show, actions.disable, actions.enable, actions.required, actions.optional],
  Authorizations: [actions.hide, actions.show, actions.disable, actions.enable, actions.required, actions.optional, actions.filter, actions.filterExclude],
  BlockElement: [actions.hide, actions.show],
  CamundaAddressChangeProcesses: [actions.hide, actions.show, actions.disable, actions.enable, actions.required, actions.optional],
  Check: [actions.hide, actions.show, actions.disable, actions.enable, actions.required, actions.optional],
  CodeSelect: [actions.hide, actions.show, actions.disable, actions.enable, actions.filter, actions.filterExclude, actions.required, actions.optional],
  ConsultantSelect: [actions.hide, actions.show, actions.disable, actions.enable, actions.filter, actions.filterExclude, actions.required, actions.optional],
  ContractAccountSelection: [actions.hide, actions.show, actions.required, actions.optional, actions.disable, actions.enable, actions.filter, actions.filterExclude],
  Contracts: [actions.hide, actions.show, actions.disable, actions.enable, actions.required, actions.optional, actions.filter, actions.filterExclude],
  CustomerAccountPositions: [actions.hide, actions.show, actions.disable, actions.enable, actions.required, actions.optional],
  CustomerAddresses: [actions.hide, actions.show, actions.disable, actions.enable, actions.required, actions.optional],
  CustomerCards: [actions.hide, actions.show, actions.disable, actions.enable, actions.required, actions.optional, actions.filter, actions.filterExclude],
  CustomerContacts: [actions.show, actions.hide, actions.required, actions.optional, actions.enable, actions.disable, actions.filter, actions.filterExclude],
  CustomerFinancingTranchesBalancing: [actions.hide, actions.show, actions.disable, actions.enable, actions.required, actions.optional],
  CustomerLsv: [actions.hide, actions.show, actions.disable, actions.enable, actions.required, actions.optional, actions.filter, actions.filterExclude],
  CustomerPortfolios: [actions.hide, actions.show, actions.disable, actions.enable, actions.required, actions.optional, actions.filter, actions.filterExclude],
  CustomerProducts: [actions.hide, actions.show, actions.enable, actions.disable, actions.required, actions.optional, actions.filter, actions.filterExclude],
  CustomerRelations: [actions.hide, actions.show, actions.enable, actions.disable, actions.required, actions.optional, actions.filter, actions.filterExclude],
  CustomerSelection: [actions.show, actions.hide, actions.required, actions.optional, actions.enable, actions.disable],
  DateField: [actions.hide, actions.show, actions.disable, actions.required, actions.optional, actions.enable],
  DiscussionParticipants: [actions.hide, actions.show, actions.required, actions.optional, actions.disable, actions.enable],
  EDossierCustomerDocuments: [actions.hide, actions.show],
  EDossierUpload: [actions.show, actions.hide, actions.required, actions.optional],
  FieldGroup: [actions.hide, actions.show, actions.disable, actions.enable],
  FileUpload: [actions.show, actions.hide],
  FundinvestFunds: [actions.hide, actions.show, actions.disable, actions.enable, actions.required, actions.optional],
  InfoBox: [actions.hide, actions.show],
  Input: [actions.hide, actions.show, actions.disable, actions.enable, actions.required, actions.optional, actions.enable, actions.unset],
  Mortgages: [actions.hide, actions.show, actions.disable, actions.required, actions.optional],
  MultiSelect: [actions.hide, actions.show, actions.disable, actions.filter, actions.filterExclude, actions.required, actions.optional],
  NumberField: [actions.hide, actions.show, actions.disable, actions.enable, actions.required, actions.optional],
  OverwriteRecipient: [actions.hide, actions.show, actions.filter, actions.filterExclude],
  PartnerContracts: [actions.hide, actions.show, actions.disable, actions.enable, actions.required, actions.optional, actions.filter, actions.filterExclude],
  PersonalAndPowerOfAttorneyAccounts: [actions.hide, actions.show, actions.required, actions.optional, actions.disable, actions.enable],
  PostActionCondition: [actions.postActionConditionFulfilled, actions.postActionConditionNotFulfilled],
  SingleSelect: [actions.hide, actions.show, actions.disable, actions.enable, actions.filter, actions.filterExclude, actions.required, actions.optional, actions.unset],
  Toggle: [actions.hide, actions.show, actions.disable, actions.enable, actions.required, actions.optional]
};

export const conditionTypes = {
  single: 'Einfache Bedingung',
  and: 'UND Bedingung',
  or: 'ODER Bedingung'
};
