import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { createStructuredSelector } from 'reselect';
import { get, isNil } from 'lodash';
import { injectIntl } from 'react-intl';
import { actions as loginActions } from '@evoja-web/react-login';
import { setLocale as setFormLocale } from '@evoja-web/react-form';

import Navigation from './Navigation';

import { handleGravitonError } from '../../utils';
import { getEnvironment, getLanguage, getSessionState } from '../../selectors/commonSelectors';
import { getError, getHasError } from '../../selectors/loginSelectors';

class Header extends React.Component {
  constructor() {
    super();

    this.setLanguage = this.setLanguage.bind(this);
  }

  setLanguage(lang) {
    const {
      language,
      loginActions
    } = this.props;

    setFormLocale(`${language}-CH`);
    loginActions.setLanguageRequest({ payload: lang });
  }

  render() {
    const {
      environment,
      error,
      hasError,
      intl,
      language,
      session
    } = this.props;
    const loginState = !isNil(session);

    if (hasError) {
      const errorMsg = get(error, 'response.data.translateId')
        ? intl.formatMessage({ id: get(error, 'response.data.translateId') })
        : handleGravitonError(error);

      toast.error(errorMsg, { position: toast.POSITION.TOP_LEFT, toastId: 'Header' });
    }

    return (
      <Navbar collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/">
              <img
                src={`/images/${environment.tenant}/logo.png`}
                alt="Logo"
              />
            </Link>
          </Navbar.Brand>
        </Navbar.Header>
        <Navbar.Collapse>
          <Navigation
            loginState={loginState}
            language={language}
            logout={this.logout}
            setLanguage={this.setLanguage}
          />
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

Header.propTypes = {
  environment: PropTypes.object,
  error: PropTypes.object,
  hasError: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  language: PropTypes.string,
  loginActions: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired
};

Header.defaultProps = {
  environment: {},
  error: undefined,
  hasError: false,
  language: 'de'
};

const mapStateToProps = createStructuredSelector({
  session: getSessionState,
  language: getLanguage,
  environment: getEnvironment,
  error: getError,
  hasError: getHasError,
});

function mapDispatchToProps(dispatch) {
  return {
    loginActions: bindActionCreators(loginActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(
    injectIntl(Header)
  )
);
