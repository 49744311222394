import React from 'react';
import { connect } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';
import { toast } from 'react-toastify';
import { createStructuredSelector } from 'reselect';
import { v4 } from 'uuid';

import { handleGravitonError } from '../utils';
import { ExternalLinkList } from '../components/ExternalLink/ExternalLinkList';
import { isExternalLinkValid } from '../components/ExternalLink/ExternalLinkValidator';
import PageContainer from '../components/Common/PageContainer';
import { EXTERNAL_LINKS_LOAD, EXTERNAL_LINKS_SAVE, setExternalLinks } from '../actions/ExternalLinkActions';
import { getLanguage } from '../selectors/commonSelectors';
import {
  getExternalLinks,
  getExternalLinksCategories,
  getExternalLinksError,
  getExternalLinksRequesting,
} from '../selectors/ExternalLinks/externalLinksCommonSelectors';

class ExternalLink extends React.Component {
  constructor(props) {
    super(props);
    this.updateLink = this.updateLink.bind(this);
    this.addLink = this.addLink.bind(this);
    this.removeLink = this.removeLink.bind(this);

    props.loadData();
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { externalLinks, setExternalLinks } = this.props;
    const sorted = arrayMove(externalLinks, oldIndex, newIndex)
      .map((item, index) => ({ ...item, order: index }));
    setExternalLinks(sorted);
  };

  updateLink(item) {
    const { externalLinks, setExternalLinks } = this.props;
    setExternalLinks(externalLinks.map((it) => it.id === item.id ? item : it));
  }

  removeLink(item) {
    const { externalLinks, setExternalLinks } = this.props;
    const updated = externalLinks.filter((it) => it.id !== item.id);
    setExternalLinks(updated);
  }

  addLink() {
    const { externalLinks, setExternalLinks } = this.props;
    const newItem = {
      id: v4(),
      order: 999999999,
      url: '',
      translatedUrl: {
        de: '',
        en: '',
        fr: '',
      },
      name: {
        de: '',
        en: '',
        fr: '',
      },
      categories: [],
    };
    setExternalLinks([...externalLinks, newItem]);
  }

  render() {
    const { requesting, externalLinks, categories, language, error, saveData } = this.props;
    if (error) {
      toast.error(handleGravitonError(error));
    }

    const isValid = externalLinks.every(link => isExternalLinkValid(link, language));
    return (
      <PageContainer
        requesting={requesting}
        saveData={saveData}
        valid={isValid}
        title={`Externe Links (${language})`}
      >
        <ExternalLinkList
          links={externalLinks}
          language={language}
          categories={categories}
          onChange={this.updateLink}
          onCreate={this.addLink}
          onRemove={this.removeLink}
          onSortEnd={this.onSortEnd}
        />
      </PageContainer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  externalLinks: getExternalLinks,
  categories: getExternalLinksCategories,
  requesting: getExternalLinksRequesting,
  language: getLanguage,
  error: getExternalLinksError,
});

const mapDispatchToProps = {
  setExternalLinks,
  loadData: () => EXTERNAL_LINKS_LOAD.request(),
  saveData: () => EXTERNAL_LINKS_SAVE.request(),
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalLink);
