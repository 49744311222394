import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Col, InputGroup } from 'react-bootstrap';
import { get, noop } from 'lodash';
import { NumberInput } from '@evoja-web/react-form';

class IsGeInput extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(key, value) {
    const { onChange } = this.props;

    onChange([value]);
  }

  render() {
    const { params } = this.props;

    return (
      <FormGroup>
        <Col sm={10}>
          <Col>
            <InputGroup>
              <InputGroup.Addon>Min</InputGroup.Addon>
              <NumberInput
                id="min"
                value={get(params, 0)}
                onChange={this.onChange}
              />
            </InputGroup>
          </Col>

        </Col>
      </FormGroup>
    );
  }
}

IsGeInput.propTypes = {
  onChange: PropTypes.func,
  params: PropTypes.array
};

IsGeInput.defaultProps = {
  onChange: noop,
  params: []
};

export default IsGeInput;
