import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import _, { get } from 'lodash';
import { withRouter } from 'react-router-dom';

import NavLink from '../components/NavLink';
import EditContainer from '../components/EditContainer';
import CheckpointList from '../components/Checkpoints/CheckpointList';
import * as actions from '../actions/CheckpointActions';

import { v4 } from 'uuid';

class Checkpoints extends React.Component {
  constructor() {
    super();
    this.state = {
      addedCheckpoints: [],
      removedCheckpoints: [],
    };
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.editCondition = this.editCondition.bind(this);
    this.remove = this.remove.bind(this);
    this.saveData = this.saveData.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(actions.getModules());
    this.props.dispatch(actions.getCheckpoints());
  }

  add() {
    const { checkpoints } = this.props;
    const newText = `Checkpoint ${this.getSelectedModule().key}-${this.getSelectedModuleCheckpoints().length + 1}`;
    const newCheckpoint = {
      id: v4(),
      condition: 'true',
      color: 'blue',
      module: this.getSelectedModuleRef(),
      text: {
        de: newText,
        en: newText,
        fr: newText,
      },
      hasChanged: true,
    };
    checkpoints.checkpoints = [...checkpoints.checkpoints, newCheckpoint];
    this.state.addedCheckpoints.push(newCheckpoint.id);
    this.props.dispatch(actions.setCheckpoints([...checkpoints.checkpoints]));
  }

  edit(id, fieldName, event) {
    const { checkpoints, language } = this.props;
    const checkpoint = checkpoints.checkpoints.find(checkpoint => checkpoint.id === id);
    const value = event.target.value;

    switch (fieldName) {
      case 'color':
        checkpoint.color = value;
        break;
      case 'module':
        checkpoint.module = value;
        break;
      case 'text':
        checkpoint.text[language] = value;
        if (!checkpoint.text.en) {
          checkpoint.text.en = value;
        }
        break;
      default:
        break;
    }

    checkpoint.hasChanged = true;

    this.props.dispatch(actions.setCheckpoints([...checkpoints.checkpoints]));
  }

  // reuse checklist condition form
  editCondition(action, module = 0, section = 0, id, event) {
    const { checkpoints } = this.props;
    const checkpoint = checkpoints.checkpoints.find(checkpoint => checkpoint.id === id);
    checkpoint.condition = event.target.value;
    checkpoint.hasChanged = true;
    this.props.dispatch(actions.setCheckpoints([...checkpoints.checkpoints]));
  }


  remove(id) {
    const { checkpoints } = this.props;
    if (this.state.addedCheckpoints.includes(id)) {
      this.removeId(this.state.addedCheckpoints, id);
      this.removeById(checkpoints.checkpoints, id);
    } else {
      this.state.removedCheckpoints.push(id);
      this.removeById(checkpoints.checkpoints, id);
    }
    this.props.dispatch(actions.setCheckpoints([...checkpoints.checkpoints]));
  }

  removeId(list, id) {
    _.remove(list, item => (item === id));
  }

  removeById(list, id) {
    _.remove(list, item => (item.id === id));
  }

  // save the changes in CheckpointStore back to the graviton service
  saveData(event) {
    event.preventDefault();
    const { checkpoints } = this.props;
    this.props.dispatch(actions.updateCheckpoints([...checkpoints.checkpoints], this.state.removedCheckpoints));
    this.props.dispatch(actions.setChanged(false));
  }

  getSelectedModuleIndex() {
    const { match } = this.props;

    return get(match, 'params.module', 0);
  }

  getSelectedModule() {
    const { modules } = this.props;
    return modules[this.getSelectedModuleIndex()];
  }

  getSelectedModuleRef() {
    return { $ref: `/core/module/${this.getSelectedModule().id}` };
  }

  getSelectedModuleCheckpoints() {
    return this.props.checkpoints.checkpoints.filter(checkpoint => (this.getModuleId(checkpoint.module) === this.getSelectedModule().id));
  }

  getModuleId(module) {
    return module ? module.$ref ? module.$ref.substr(module.$ref.lastIndexOf('/') + 1) : null : null;
  }

  // render the checkpoint list
  checkpoints() {
    const { modules, language } = this.props;

    if (modules && modules.length > 0) {
      const moduleNavLinks = modules.map((module, i) =>
        (<NavLink
          to={`/checkpoints/${i}`}
          key={i}
        >
          <span> { module.name[language] } </span>
         </NavLink>));

      return (<div>
        <ul className="nav nav-tabs"> { moduleNavLinks } </ul>
        <CheckpointList
          module={this.getSelectedModule()}
          checkpoints={this.getSelectedModuleCheckpoints()}
          language={language}
          onAdd={this.add}
          onRemove={this.remove}
          onEdit={this.edit}
          onEditCondition={this.editCondition}
          move={this.move}
        />
      </div>
      );
    }
  }


  // embed the checkpoints in to the EditContainer component
  render() {
    const {
      requesting, language, alertField, error, hasError,
    } = this.props;
    if (hasError) {
      toast.error(get(error, 'response.data.message'));
    }

    return (<EditContainer
      requesting={requesting}
      saveData={this.saveData}
      alertField={alertField}
      title={`Checkpoints (${language})`}
      body={this.checkpoints()}
      elementId="checkpoints"
    />
    );
  }
}

function mapStateToProps(state) {
  return {
    checkpoints: state.checkpoints,
    modules: state.checkpoints.modules,
    requesting: state.checkpoints.requesting,
    changed: state.checkpoints.changed,
    language: state.login.language,
    error: state.checkpoints.error,
    hasError: state.checkpoints.hasError,
  }
};

export default connect(mapStateToProps)(
  withRouter(Checkpoints)
);
