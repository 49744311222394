import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { FormGroup, ControlLabel, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { TextInput } from '@evoja-web/react-form';

const DiffLoadConfiguration = React.memo(function DiffLoadConfiguration({ id, item, editValueField }) {
  return (
    <FormGroup controlId="codeGroupMulti">
      <Col componentClass={ControlLabel} sm={3}>
        <FormattedMessage id="Workguide.Configuration.DiffLoad.DiffLoadObjects" />
      </Col>
      <Col sm={9} style={{ paddingTop: '10px' }}>
        <TextInput
          id="diffLoadObjects"
          value={get(item, 'props.diffLoadObjects', 'SingleCustomer')}
          onChange={(key, value) => editValueField(id, 'prop', { key, value })}
        />
      </Col>
    </FormGroup>
  );
});

DiffLoadConfiguration.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  item: PropTypes.object.isRequired,
  editValueField: PropTypes.func
};

DiffLoadConfiguration.defaultProps = {
  editValueField: noop,
};

export default DiffLoadConfiguration;
