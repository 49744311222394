import { all, call, put, select } from 'redux-saga/effects';
import { chain, chunk, flatten, get, isNil } from 'lodash';
import moment from 'moment';
import { ChunkLoader, handleNextHeaders, removeUnwantedValues } from '@evoja-web/redux-saga-utils';
import QueryBuilder from '@evoja-web/rql-query-builder';

import leadsAction from '../actions/Leads';

const {
  LEADS_PENDING,
  LEADS_FULFILLED,
  LEADS_REJECTED
} = leadsAction;

const queryParts = {
  customer: ({ builder, data }) => builder.in('customerId', get(data, 'customer', []).map((c) => c.id), { type: 'string' }),
  ids: ({ builder, data }) => builder.in('id', get(data, 'ids', '').split(',')),
  orderOrigin: ({ builder, data }) => {
    const ids = get(data, 'orderOrigin', []).map((o) => {
      return o.id === 'orderOrigin-PO'
        // Potenatial has multiple codes
        // https://issue.swisscom.ch/browse/MAP-9074
        ? 'orderOrigin-P6,orderOrigin-P7,orderOrigin-P8,orderOrigin-PO'
        : o.id;
    });

    return builder.in('orderOrigin.id', ids);
  },
  customerTaskType: ({ builder, data }) => builder.in('customerTaskType.id', get(data, 'customerTaskType', []).map((o) => o.id)),
  customerTaskStatus: ({ builder, data }) => builder.in('customerTaskStatus.id', get(data, 'customerTaskStatus', []).map((o) => o.id)),
  leadinstruction: ({ builder, data }) => builder.like('leadinstruction', get(data, 'leadinstruction')),
  externalReference: ({ builder, data }) => builder.eq('externalReference', get(data, 'externalReference')),
  successProduct: ({ builder, data }) => builder.in('successProduct.id', get(data, 'product', []).map((p) => p.id)),
  dueDateFrom: ({ builder, data }) => {
    const date = moment(get(data, 'dueDateFrom')).startOf('day').utc().format();
    return builder.ge('dueDate', date);
  },
  dueDateTo: ({ builder, data }) => {
    const date = moment(get(data, 'dueDateTo')).endOf('day').utc().format();
    return builder.le('dueDate', date);
  },
  expireDateFrom: ({ builder, data }) => {
    const date = moment(get(data, 'expireDateFrom')).startOf('day').utc().format();
    return builder.ge('expireDateFrom', date);
  },
  expireDateTo: ({ builder, data }) => {
    const date = moment(get(data, 'expireDateTo')).endOf('day').utc().format();
    return builder.le('expireDateTo', date);
  },
  publishDateFrom: ({ builder, data }) => {
    const date = moment(get(data, 'publishDateFrom')).startOf('day').utc().format();
    return builder.ge('publishDateFrom', date);
  },
  publishDateTo: ({ builder, data }) => {
    const date = moment(get(data, 'publishDateTo')).endOf('day').utc().format();
    return builder.le('publishDateTo', date);
  }
};

/**
 * Throw an error for unsupported form fields
 *
 * @param   {String}  key  Form element key
 *
 * @return  void
 */
function unsupportedQueryPart({ builder, key }) {
  console.warn(`No query part for field with key "${key}" registered! This may be ok in case of consultant filters but you may want to check this..`);

  return builder;
}

/**
 * Default load if no consultant filter is set.
 *
 * @param   {QueryBuilder}  builder  Query builder instance
 *
 * @return  {Array} result Result from backend call
 */
function* load({ builder }) {
  const query = builder
    .limit(200)
    .getQueryString();

  const url = `/work/leadmanagement/${query}`;
  const result = yield call(handleNextHeaders, url);

  return result;
}

/**
 * Use the chunk loader in case of some kind of consultant filter (consultant select, location, region..)
 * as the resulting query string my exceed the url length limit.
 *
 * @param   {QueryBuilder}  builder      Query builder instance
 * @param   {Array}         consultants  Array of consultant ids
 *
 * @return  {Array} result Result from chunk calls (flat array)
 */
function* loadInChunks({ builder, consultants }) {
  const cl = ChunkLoader()
    .setQueryBuilder(builder)
    .setServiceUrl('/work/leadmanagement/')
    .setKeys(['staffMemberId', 'additionalEditor'])
    .setChunkSize(10)
    .setIdentifiers(consultants, { type: 'string' });

  const result = yield call(cl.execute);

  return result;
}

/**
 * Get all consultants by location name
 *
 * @param   {Array}   consultants  Consultants array
 * @param   {String}  name         Location name
 *
 * @return  {Array} consultants Array of consultants on this location
 */
function getConsultantIdsByLocationName({ consultants = [], name = '' }) {
  return consultants
    .filter((c) => !isNil(get(c, 'location.name')) && get(c, 'location.name') === name)
    .map((c) => c.id);
}

/**
 * Get all consultants by location description
 *
 * @param   {Array}   consultants  Consultants array
 * @param   {String}  description  Location description
 *
 * @return  {Array} consultants Array of consultants on this location
 */
function getConsultantIdsByLocationDescription({ consultants = [], description = '' }) {
  return consultants
    .filter((c) => !isNil(get(c, 'location.description')) && get(c, 'location.description') === description)
    .map((c) => c.id);
}

/**
 * Special case consultants...
 * Leads only contain a minimal set of information about the consultant / staffMember due to some legacy performance optimizations.
 * Sadly the location is missing which would be necessary to be able to filter by.
 * To solve this problem, collect all consultants (consultant select, location select, region select) and add an in() filter.
 * As the resulting query may exceed the maximum url length, use the ChunkLoader to load data in chunks.
 *
 * @param   {Object}  formData  Form data
 *
 * @return  {Array} consultants Array of consultant ids
 */
function* getConsultantsFromFormData({ formData }) {
  // Consultants form consultant select
  const allConsultants = yield select((state) => get(state, 'leadMassMutation.consultants.data', []));
  const consultants = get(formData, 'consultant', []).map((c) => c.id);

  // Consultants from location select
  const location = chain(formData)
    .get('location', [])
    .map((l) => getConsultantIdsByLocationName({ consultants: allConsultants, name: l.id }))
    .flatten()
    .uniq()
    .value();

  // Consultants from region select
  const region = chain(formData)
    .get('region', [])
    .map((l) => getConsultantIdsByLocationDescription({ consultants: allConsultants, description: l.id }))
    .flatten()
    .uniq()
    .value();

  // Concat, remove undefined and remove duplicates
  return chain([
    ...consultants,
    ...location,
    ...region
  ])
    .compact()
    .uniq()
    .value();
}

function* loadLeads({ builder, consultants,  }) {
  const payload = get(consultants, 'length', 0) > 0
    ? yield call(loadInChunks, { builder, consultants })
    : yield call(load, { builder });

  return payload;
}

function createBuilder({ formData }) {
  const builder = chain(formData)
    .thru((data) => removeUnwantedValues(data, [undefined, null]))
    .reduce((builder, value, key) => get(queryParts, key, unsupportedQueryPart)({ builder, data: formData, key }), QueryBuilder())
    .value();

  return builder;
}

export default function* getLeads(request) {
  yield put({ type: LEADS_PENDING });

  try {
    const { formData } = request;
    const chunkSize = 50;

    let payload;

    const consultants = yield call(getConsultantsFromFormData, { formData });

    const ids = get(formData, 'ids', '').split(',');
    // load leads in chunks, in case that there are more than 50 lead ids
    // because the rql query will be to long...
    // https://issue.swisscom.ch/browse/MAP-9474
    if (get(ids, 'length', 0) > chunkSize) {
      const idChunks = chunk(ids, chunkSize);

      const leadChunks = idChunks.map((ids) => {
        const data = { ...formData, ids: ids.join(',') };
        const builder = createBuilder({ formData: data });
        return call(loadLeads, { builder, consultants });
      });

      const leadList = yield all(leadChunks);

      payload = flatten(leadList);
    } else {
      // Create query from form data
      const builder = createBuilder({ formData });
      payload = yield call(loadLeads, { builder, consultants });
    }

    yield put({ type: LEADS_FULFILLED, payload });

    return payload;
  } catch (error) {
    yield put({ type: LEADS_REJECTED, error });

    return error;
  }
}
