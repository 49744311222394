import React from 'react';
import PropTypes from 'prop-types';
import { chain, noop } from 'lodash';
import { Select } from '@evoja-web/react-form';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

class WorkguidePermissions extends React.PureComponent {
  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      id,
      onChange,
      permissions,
      roles,
      value
    } = this.props;

    // Create options by roles / permissions
    const options = chain(roles)
      .map((role) => permissions.map((permission) => ({
        value: `${permission.scope}.${permission.id}.${role.id}`,
        label: `${role.label} ${permission.label}`
      })))
      .flatten()
      .value();

    return (
      <FormGroup>
        <ControlLabel>
          <FormattedMessage id="Workguide.General.Configuration.Permissions.Title" />
        </ControlLabel>

        <Select
          id={id}
          multi
          onChange={onChange}
          options={options}
          value={value}
        />
      </FormGroup>
    );
  }
}

WorkguidePermissions.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  permissions: PropTypes.array,
  roles: PropTypes.array,
  value: PropTypes.array
};

WorkguidePermissions.defaultProps = {
  id: 'permissions',
  onChange: noop,
  permissions: [],
  roles: [],
  value: []
};

export default WorkguidePermissions;
