import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, set } from 'lodash';
import NumberFormat from 'react-number-format';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';

class ArrayValuesLeValidationConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.onMaxChange = this.onMaxChange.bind(this);
    this.onPathChange = this.onPathChange.bind(this);
  }

  onMaxChange({ floatValue }) {
    const {
      onChange,
      params
    } = this.props;

    const updated = set(
      [...params],
      0,
      floatValue
    );

    onChange(updated);
  }

  onPathChange(ev) {
    const {
      onChange,
      params
    } = this.props;

    const v = get(ev, 'target.value');
    const updated = set(
      [...params],
      1,
      v
    );

    onChange(updated);
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { params } = this.props;

    return (
      <div style={{ paddingRight: '15px', paddingLeft: '15px' }}>
        <FormGroup>
          <ControlLabel>
            Maximum Wert
          </ControlLabel>

          <NumberFormat
            id="max-value"
            className="form-control"
            value={get(params, 0)}
            onValueChange={this.onMaxChange}
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel>
            Pfad zu Wert (optional) Wenn es sich um ein Array von Objekten handelt
          </ControlLabel>

          <FormControl
            type="text"
            id="object-path"
            value={get(params, 1)}
            onChange={this.onPathChange}
          />
        </FormGroup>
      </div>
    );
  }
}

ArrayValuesLeValidationConfiguration.propTypes = {
  onChange: PropTypes.func,
  params: PropTypes.array
};

ArrayValuesLeValidationConfiguration.defaultProps = {
  onChange: noop,
  value: []
};

export default ArrayValuesLeValidationConfiguration;
