import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormGroup, ControlLabel, Col } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { FormattedMessage } from 'react-intl';

import HiddenCols from './HiddenCols';

const CustomerCardsConfiguration = React.memo(function CustomerCardsConfiguration({
  id,
  item,
  editValueField
}) {
  return (
    <React.Fragment>
      <FormGroup controlId="codeGroupMulti">
        <Col componentClass={ControlLabel} sm={3}>
          <FormattedMessage id="Workguide.Configuration.Common.MultiSelect" />
        </Col>
        <Col sm={9} style={{ paddingTop: '10px' }}>
          <Toggle
            checked={_.get(item, 'props.multi') === undefined ? false : item.props.multi}
            onChange={() => editValueField(id, 'multi')}
          />
        </Col>
      </FormGroup>

      <FormGroup>
        <Col componentClass={ControlLabel} sm={3}>
          Spaten anzeigen
        </Col>
        <Col sm={9} style={{ paddingTop: '10px' }}>
          <HiddenCols
            component={item}
            options={[{
              value: 'cardType',
              label: 'Art'
            }, {
              value: 'cardNumber',
              label: 'Nr.'
            }, {
              value: 'ownDailyLimit',
              label: 'Eigene tägliche Bargeldlimite'
            }, {
              value: 'ownMonthlyLimit',
              label: 'Eigene monatliche Bargeldlimite'
            }, {
              value: 'monthlyLimit',
              label: 'Offline-Kartenlimite'
            }, {
              value: 'customerName',
              label: 'Inhaber'
            }, {
              value: 'suspension',
              label: 'Sperrcode'
            }, {
              value: 'visecaAccountNumber',
              label: 'Viseca Kontonummer'
            }]}
            onChange={(value) => editValueField(id, 'hiddenCols', value)}
          />
        </Col>
      </FormGroup>
    </React.Fragment>
  );
});

CustomerCardsConfiguration.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  item: PropTypes.object.isRequired,
  editValueField: PropTypes.func
};

CustomerCardsConfiguration.defaultProps = {
  editValueField: _.noop
};

export default CustomerCardsConfiguration;
