import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { ActionConfigurationNoteToEDossier } from '../../../../packages/Workguide/index';

const NoteToEDossierPostActionConfig = React.memo(function NoteToEDossierPostActionConfig({
  onChange,
  params
}) {
  const {
    filename,
    formNr,
    searchValue
  } = params;

  const action = {
    filename,
    formNr,
    searchValue
  };

  return (
    <ActionConfigurationNoteToEDossier
      action={action}
      onValueChange={onChange}
    />
  );
});

NoteToEDossierPostActionConfig.propTypes = {
  onChange: PropTypes.func,
  params: PropTypes.object
};

NoteToEDossierPostActionConfig.defaultProps = {
  onChange: noop,
  params: {}
};

export default NoteToEDossierPostActionConfig;
