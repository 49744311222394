import './EmailSubscriptionsCreateItem.css';

import React from 'react';
import { connect } from 'react-redux';
import { Transition } from 'react-transition-group';
import { createStructuredSelector } from 'reselect';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ModalBody, ModalDialog, ModalHeader } from '../Common/Modal';
import { EmailSubscriptionsEditForm } from './EmailSubscriptionsEditForm';
import { getEmailSubscriptionsLoading } from '../../selectors/EmailSubscriptions/emailSubscriptionsCommonSelectors';
import {
  getEmailSubscriptionsStateOptions,
  getEmailSubscriptionsTypeOptions,
} from '../../selectors/EmailSubscriptions/emailSubscriptionsOptionsSelector';
import { EMAIL_SUBSCRIPTIONS_SAVE } from '../../actions/EmailSubscriptionsActions';
import { validateEmailSubscriptionsEditForm } from '../../services/EmailSubscriptions/EmailSubscriptionsEditFormValidator';
import { Button } from '../Common/Button';

class EmailSubscriptionsCreateItemConnected extends React.PureComponent {
  state = {
    id: undefined,
    isModalOpen: false,
  };

  render() {
    return (
      <div className="email-subscriptions-create">
        <Button bsStyle="primary" onClick={this.openModal}>
          <FormattedMessage id="emailSubscriptions_add"/>
        </Button>
        {this.renderModal()}
      </div>
    );
  }

  renderModal() {
    const { isModalOpen } = this.state;
    const { loading, subscriptionTypeOptions, subscriptionStateOptions } = this.props;
    return (
      <ModalDialog show={isModalOpen} onHide={this.closeModal}>
        <ModalHeader closeButton>
          <Modal.Title className="email-subscriptions-create__modal-title">
            <FormattedMessage id="emailSubscriptions_newItemTitle"/>
          </Modal.Title>
        </ModalHeader>
        <ModalBody>
          <Transition
            in={isModalOpen}
            mountOnEnter={true}
            unmountOnExit={true}
            timeout={{ enter: 0, exit: 500 }}
          >
            <EmailSubscriptionsEditForm
              form="emailSubscriptionsCreateForm"
              save={this.save}
              saving={loading}
              close={this.closeModal}
              subscriptionTypeOptions={subscriptionTypeOptions}
              subscriptionStateOptions={subscriptionStateOptions}
              validate={validateEmailSubscriptionsEditForm}
            />
          </Transition>
        </ModalBody>
      </ModalDialog>
    );
  }

  save = (formData) => {
    const { save } = this.props;
    save(formData);
    this.closeModal();
  };
  openModal = () => this.setState({ isModalOpen: true});
  closeModal = () => this.setState({ isModalOpen: false});
}

const mapStateToProps = createStructuredSelector({
  loading: getEmailSubscriptionsLoading,
  subscriptionTypeOptions: getEmailSubscriptionsTypeOptions,
  subscriptionStateOptions: getEmailSubscriptionsStateOptions,
});

const mapDispatchToProps = {
  save: (formData) => EMAIL_SUBSCRIPTIONS_SAVE.request(formData),
};

export const EmailSubscriptionsCreateItem = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailSubscriptionsCreateItemConnected);
