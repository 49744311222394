import React, { Component } from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';

class CopyButton extends Component {
  constructor(props) {
    super(props);
    this.state = { copied: false };

    this.onClick = this.onClick.bind(this);
  }

  onClick(ev) {
    const { value } = this.props;

    ev.preventDefault();
    ev.stopPropagation();

    copy(value);
    this.setState({ copied: true });

    setTimeout(() => this.setState({ copied: false }), 500);
  }

  render() {
    const { copied } = this.state;
    const { value } = this.props;

    return (
      <div
        style={{ cursor: 'pointer' }}
        onClick={this.onClick}
      >
        {(!value) ? null : (
          <i
            id="clipboard"
            className={(copied)
              ? 'mdi mdi-clipboard-check'
              : 'mdi mdi-clipboard-outline'
            }
          />
        )}
      </div>
    );
  }
}

CopyButton.propTypes = {
  value: PropTypes.string
};

CopyButton.defaultProps = {
  value: []
};

export default CopyButton;
